var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DisplayContainer',{attrs:{"label":"Kontaktdaten"}},[_c('div',{staticClass:"input-row"},[_c('DataDisplay',{attrs:{"label":"Vertragsnummer"}},[(_vm.contractId)?_c('div',[_vm._v(" "+_vm._s(_vm.contractId)+" ")]):_vm._e()]),_c('DataDisplay',{attrs:{"label":"Kundennummer"}},[(_vm.customerId)?_c('div',[_vm._v(" "+_vm._s(_vm.customerId)+" ")]):_vm._e()])],1),_c('DisplayContainer',{attrs:{"label":"Installationsadresse","isSubcomponent":true}},[_c('ContactInformation',{attrs:{"addressDto":_vm.installationAddress}})],1)],1),_c('DisplayContainer',{attrs:{"label":"Produkte"}},[_c('AllOrderedProductsTable',{attrs:{"allProducts":_vm.allProducts}})],1),_c('DisplayContainer',{attrs:{"label":"Geräte"}},[_c('DisplayContainer',{attrs:{"label":"ONT Konfigurationen","isSubcomponent":true}},[_c('div',{staticClass:"margin-top-1"},[_c('ProductSelection',{attrs:{"productType":"ONT","isRequired":false,"disabled":!_vm.ontProductCanBeSelected,"presentProductData":_vm.availableOntProduct,"previouslySelectedProduct":_vm.selectedOntProduct},on:{"selectedProductChanged":function($event){{
              _vm.selectedOntProduct = $event;
              _vm.pushData();
            }}}})],1),_c('HardwareDeviceSelection',{attrs:{"disabled":_vm.userDataLoading || _vm.updatedMsanConfigurationLoading,"presentDeviceData":_vm.presentONTDeviceData,"deviceType":_vm.deviceType},on:{"selectedDeviceChanged":function($event){{
            _vm.selectedONTDevice = $event;
            _vm.updateUpdatedMsanConfiguration();
            _vm.pushData();
          }},"inputIsValidChanged":function($event){_vm.selectedONTDeviceValid = $event}}})],1),_c('DisplayContainer',{attrs:{"isSubcomponent":true,"label":"GPON Konfigurationen"}},[_c('UniPortSelection',{attrs:{"disabled":_vm.userDataLoading || _vm.updatedMsanConfigurationLoading,"presentUniPortid":_vm.availableUniPortId},on:{"selectedUniPortChanged":function($event){{
            _vm.selectedUniPort = $event;
            _vm.updateUpdatedMsanConfiguration();
            _vm.pushData();
          }},"inputIsValidChanged":function($event){_vm.selectedUniPortValid = $event}}})],1)],1),_c('DisplayContainer',{attrs:{"label":"MSAN-Konfiguration"}},[_c('MsanProvisioningInformation',{attrs:{"provisioningInformation":_vm.msanProvisioningInformation}}),_c('MSANConfiguration',{attrs:{"data":_vm.updatedMsanConfiguration,"dataIsLoading":_vm.updatedMsanConfigurationLoading}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }