<template>
  <div>
    <v-card elevation="1">
      <div class="components-grid">
        <div class="step-wrapper">
          <div class="description">
            Aktueller Schritt:
          </div>
          <div class="step">
            {{ currentStep }}
          </div>
        </div>
        <div class="process-step-wrapper">
          <SearchCustomer
            v-if="currentStep == steps.SEARCH_CUSTOMER_DATA"
            :isLoading="loading"
            :state="currentState.searchData"
            label="Support Start"
            @inputValidationChanged="validationErrors = $event"
            @stateChanged="currentState.searchData = $event"
          />

          <SupportDashboard
            v-if="currentStep == steps.CUSTOMER_META_DATA"
            :customerId="currentState.searchData.selectedContract.customerId"
            :isVario="
              currentState.searchData.selectedContract.system == 'VarioCRM'
            "
            :crmSystem="currentState.searchData.selectedContract.system"
          />
        </div>
        <div class="step-navigation-wrapper">
          <v-btn
            v-if="displayBackButton"
            class="backButton"
            color="primary"
            large
            @click="updateFsm(availableSignals.BACKWARD)"
          >
            Zurück
          </v-btn>
          <v-tooltip
            v-if="displayForwardButton"
            :disabled="validationErrors.length === 0"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="forwardButton"
                v-bind="attrs"
                v-on="on"
              >
                <v-btn
                  :disabled="!currentInputsValid"
                  color="primary"
                  large
                  @click="updateFsm(availableSignals.FORWARD)"
                >
                  Nächster Schritt
                </v-btn>
              </div>
            </template>
            <ul>
              <li
                v-for="(error, index) in validationErrors"
                :key="index"
              >
                {{ error }}
              </li>
            </ul>
          </v-tooltip>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';
import SearchCustomer from '@/components/SearchCustomer.vue';
import SupportDashboard from '@/components/support/SupportDashboard.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import NotificationObject from '@/main/NotificationObject.js';

export default {
  name: 'SupportStartWrapper',
  components: {
    SearchCustomer,
    SupportDashboard
  },
  data: () => ({
    currentState: null,
    currentStep: null,
    contractDataLoading: false,
    processStarting: false,
    validationErrors: []
  }),
  computed: {
    loading() {
      return this.contractDataLoading;
    },
    displayBackButton() {
      return this.currentStep !== this.steps.SEARCH_CUSTOMER_DATA;
    },
    displayForwardButton() {
      return this.currentStep !== this.steps.CUSTOMER_META_DATA;
    },
    displayCompleteButton() {
      return this.currentStep === this.steps.CUSTOMER_META_DATA;
    },
    currentInputsValid() {
      return this.validationErrors.length === 0;
    },
    urlSearchParams() {
      return {
        page: this.currentStep,
        customerId: this.currentState.searchData.selectedContract.customerId,
        system: this.currentState.searchData.selectedContract.system
      };
    }
  },

  created() {
    this.steps = {
      SEARCH_CUSTOMER_DATA: 'Kundensuche',
      CUSTOMER_META_DATA: 'Kundenübersicht'
    };
    this.availableSignals = {
      BACKWARD: {
        id: 'BACKWARD'
      },
      FORWARD: {
        id: 'FORWARD'
      }
    };
    this.initState();
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    initState: function () {
      this.currentState = {
        selectedContract: undefined,
        customerId: undefined,
        customerContracts: undefined,
        ontProductId: undefined,
        ontDeviceId: undefined,
        uniPortId: undefined,
        searchData: {
          selectedContract: undefined,
          customerId: undefined,
          customerContracts: undefined,
          recallDate: null,
          searchState: {
            customerName: null,
            billId: null,
            customerId: null,
            contractId: null,
            firstName: null,
            lastName: null,
            contactAddress: {
              zipCode: undefined,
              city: undefined,
              street: undefined,
              houseNumber: undefined,
              houseNumberSupplement: undefined
            }
          }
        }
      };
      this.currentStep = this.steps.SEARCH_CUSTOMER_DATA;

      // redirect to customer page
      const { query } = this.$route;
      if (
        Object.prototype.hasOwnProperty.call(query, 'page') &&
        query.page === this.steps.CUSTOMER_META_DATA &&
        Object.prototype.hasOwnProperty.call(query, 'customerId')
      ) {
        this.currentState.searchData.selectedContract = {};
        this.currentStep = query.page;
        if (!Object.prototype.hasOwnProperty.call(query, 'system')) {
          // add fallback system
          query.system = 'VarioCRM';
        }
        Object.keys(query).forEach((key) => {
          switch (key) {
            case 'page': {
              this.currentStep = query[key];
              break;
            }
            case 'customerId': {
              this.currentState.searchData.selectedContract.customerId =
                parseInt(query[key]);
              break;
            }
            default: {
              this.currentState.searchData.selectedContract[key] = query[key];
              break;
            }
          }
        });
      }
    },
    pushData(retrieveInfo) {
      this.currentState.ontProductId = retrieveInfo.selectedOntProduct?.id;
      this.currentState.ontDeviceId =
        retrieveInfo.selectedONTDevice?.serviceDataId;
      this.currentState.uniPortId = retrieveInfo.selectedUniPort?.serviceDataId;
      this.validationErrors = retrieveInfo.validationErrors;
    },
    updateFsm: function (signal) {
      switch (this.currentStep) {
        case this.steps.SEARCH_CUSTOMER_DATA:
          // only forward possible
          this.currentStep = this.steps.CUSTOMER_META_DATA;
          // push page state to url
          history.pushState(
            {},
            null,
            this.$route.path +
              '?' +
              new URLSearchParams(this.urlSearchParams).toString()
          );
          break;
        case this.steps.CUSTOMER_META_DATA:
          // only backwards possible
          this.currentStep = this.steps.SEARCH_CUSTOMER_DATA;
          // push page state to url
          history.pushState(
            {},
            null,
            this.$route.path +
              '?' +
              new URLSearchParams({ page: this.currentStep }).toString()
          );
          break;
      }
      window.scrollTo(0, 0);
    },
    startProcess(ontProductId, ontDeviceId, uniPortId) {
      this.processStarting = true;
      HTTP.post('msanConfiguration/start', {
        contractId: this.currentState.searchData.selectedContract.contractId,
        ontProductId: ontProductId || null,
        ontId: ontDeviceId,
        uniPortId: uniPortId
      })
        .then((resp) => {
          this.showMessage(
            'success',
            'Der Prozess wurde erfolgreich gestartet.'
          );
          this.$router.push('/');
        })
        .catch((err) => {
          console.log('error', err);
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Der Prozess konnte nicht gestartet werden.'
            )
          );
        })
        .finally(() => {
          this.processStarting = false;
        });
    }
  }
};
</script>
<style scoped>
.components-grid {
  display: grid;
  grid-template-rows: max-content max-content auto max-content;
}

.title-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  color: var(--goe-fontColor-dark);
  font-weight: var(--goe-fontWeight-light);
  padding: var(--goe-spacing-2);
  padding-bottom: var(--goe-spacing-1_5);
  background: var(--goe-background-highlight);
}

.title-wrapper h2 {
  margin-top: var(--goe-spacing-0_5);

  font-size: var(--goe-fontSize-normal);
  color: var(--goe-fontColor-dark);
  text-transform: uppercase;
  font-weight: var(--goe-fontWeight-light);
}

.step-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: var(--goe-spacing-2);
  margin: var(--goe-spacing-2);
  margin-left: auto;
  width: max-content;
}

.process-step-wrapper {
  display: grid;
  grid-template-columns: auto;

  padding: var(--goe-spacing-2);
}

.step-navigation-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  margin: var(--goe-spacing-2);
  padding: var(--goe-spacing-0) var(--goe-spacing-2);
  grid-template-areas: 'back forward ';
}

.step-navigation-wrapper > .backwardButton {
  grid-area: backward;
}

.step-navigation-wrapper > .forwardButton {
  grid-area: forward;
}

.step-navigation-wrapper NormalButton:nth-of-type(1) {
  order: 2;
}

.step-navigation-wrapper NormalButton:nth-of-type(2) {
  order: 1;
}

.step {
  color: var(--goe-fontColor-highlight);
  font-weight: var(--goe-fontWeight-bold);
  font-size: var(--goe-fontSize-normal);
}
</style>
