import { render, staticRenderFns } from "./ProcessStart.vue?vue&type=template&id=b4a17b32&scoped=true&"
import script from "./ProcessStart.vue?vue&type=script&lang=js&"
export * from "./ProcessStart.vue?vue&type=script&lang=js&"
import style0 from "./ProcessStart.vue?vue&type=style&index=0&id=b4a17b32&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4a17b32",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
