<template>
  <div>
    <DisplayContainer label="Kontaktdaten">
      <div class="input-row">
        <DataDisplay label="Vertragsnummer">
          <div v-if="contractId">
            {{ contractId }}
          </div>
        </DataDisplay>
        <DataDisplay label="Kundennummer">
          <div v-if="customerId">
            {{ customerId }}
          </div>
        </DataDisplay>
      </div>
      <DisplayContainer label="Installationsadresse" :isSubcomponent="true">
        <ContactInformation :addressDto="installationAddress" />
      </DisplayContainer>
    </DisplayContainer>
    <DisplayContainer label="Produkte">
      <AllOrderedProductsTable :allProducts="allProducts" />
    </DisplayContainer>
    <DisplayContainer label="Geräte">
      <DisplayContainer label="ONT Konfigurationen" :isSubcomponent="true">
        <div class="margin-top-1">
          <ProductSelection
            productType="ONT"
            :isRequired="false"
            :disabled="!ontProductCanBeSelected"
            :presentProductData="availableOntProduct"
            :previouslySelectedProduct="selectedOntProduct"
            @selectedProductChanged="
              {
                selectedOntProduct = $event;
                pushData();
              }
            "
          />
        </div>
        <HardwareDeviceSelection
          :disabled="userDataLoading || updatedMsanConfigurationLoading"
          :presentDeviceData="presentONTDeviceData"
          :deviceType="deviceType"
          @selectedDeviceChanged="
            {
              selectedONTDevice = $event;
              updateUpdatedMsanConfiguration();
              pushData();
            }
          "
          @inputIsValidChanged="selectedONTDeviceValid = $event"
        />
      </DisplayContainer>
      <DisplayContainer :isSubcomponent="true" label="GPON Konfigurationen">
        <UniPortSelection
          :disabled="userDataLoading || updatedMsanConfigurationLoading"
          :presentUniPortid="availableUniPortId"
          @selectedUniPortChanged="
            {
              selectedUniPort = $event;
              updateUpdatedMsanConfiguration();
              pushData();
            }
          "
          @inputIsValidChanged="selectedUniPortValid = $event"
        />
      </DisplayContainer>
    </DisplayContainer>
    <DisplayContainer label="MSAN-Konfiguration">
      <MsanProvisioningInformation
        :provisioningInformation="msanProvisioningInformation"
      />
      <MSANConfiguration
        :data="updatedMsanConfiguration"
        :dataIsLoading="updatedMsanConfigurationLoading"
      />
    </DisplayContainer>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import ContactInformation from '@/components/ContactInformation.vue';
import AllOrderedProductsTable from '@/components/AllOrderedProductsTable.vue';
import MSANConfiguration from '@/components/deviceProvisioning/MSANConfiguration.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';
import HardwareDeviceSelection from '@/components/deviceProvisioning/HardwareDeviceSelection.vue';
import UniPortSelection from '@/components/deviceProvisioning/UniPortSelection.vue';
import NotificationObject from '@/main/NotificationObject.js';
import ProductSelection from '@/components/deviceProvisioning/ProductSelection.vue';
import MsanProvisioningInformation from '@/components/deviceProvisioning/MsanProvisioningInformation.vue';

export default {
  name: 'MSANProvisioning',
  components: {
    DataDisplay,
    DisplayContainer,
    ContactInformation,
    AllOrderedProductsTable,
    MSANConfiguration,
    HardwareDeviceSelection,
    UniPortSelection,
    ProductSelection,
    MsanProvisioningInformation
  },
  props: {
    contractId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    customerId: null,
    invoiceAddress: null,
    installationAddress: null,
    ownerAddress: null,
    products: null,
    allProducts: null,
    presentONTDeviceData: null,
    selectedONTDeviceValid: false,
    selectedONTDevice: null,
    selectedUniPort: null,
    selectedUniPortValid: false,
    updatedMsanConfiguration: null,
    updatedMsanConfigurationLoading: false,
    userDataLoading: false,
    availableOntProduct: undefined,
    selectedOntProduct: undefined,
    msanProvisioningInformation: undefined,
    availableUniPortId: undefined,
    deviceType: undefined
  }),
  computed: {
    ontProductCanBeSelected() {
      return (
        this.availableOntProduct === null ||
        this.availableOntProduct === undefined
      );
    }
  },
  mounted: function () {
    this.loadUserData(this.contractId);
  },
  methods: {
    loadUserData: function (contractId) {
      this.userDataLoading = true;
      Promise.allSettled(
        [
          this.retrieveCustomerId(this.contractId),
          this.retrieveInstallationAddress(this.contractId),
          this.retrieveProducts(this.contractId),
          this.retrieveAvailableUniPortId(this.contractId)
        ],
        this.loadONTInformation(this.contractId)
      )
        .then((responses) => {
          if (responses.some((response) => response.status === 'rejected')) {
            this.showMessage(
              'error',
              'Die Kundendaten konnten nicht vollständig geladen werden!'
            );
          }
        })
        .catch((e) => {
          this.showMessage(
            'error',
            'Die Prozessdaten konnten nicht geladen werden!'
          );
        })
        .finally(() => (this.userDataLoading = false));
    },
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    retrieveCustomerId: function (contractId) {
      return HTTP.get(`/contracts/customer/id/${contractId}`).then(
        (response) => {
          this.customerId = response.data;
        }
      );
    },
    retrieveInstallationAddress: function (contractId) {
      return HTTP.get(
        `/customer/addressByType/${contractId}/INSTALLATION`
      ).then((response) => {
        this.installationAddress = response.data;
      });
    },
    retrieveProducts: function (contractId) {
      return HTTP.get(`/products/contract/all/${contractId}`).then(
        (response) => {
          this.allProducts = response.data;
        }
      );
    },
    loadONTInformation: function (contractId) {
      return HTTP.get(`/products/contract/cpe/${contractId}`, {
        params: {
          cpeDeviceCategory: 'ONT'
        }
      }).then((res) => {
        const ontDevice = res.data.device;
        this.availableOntProduct = res.data.product;
        if (ontDevice) {
          this.presentONTDeviceData = {
            serviceDataId: ontDevice.serviceDataId,
            serialNumber: ontDevice.serialNumber
          };
          this.deviceType = ontDevice.cpeDeviceType;
        } else {
          this.presentONTDeviceData = undefined;
        }
        this.deviceType =
          this.deviceType || this.availableOntProduct?.requiredDevice || 'ONT';
      });
    },
    retrieveAvailableUniPortId: function (contractId) {
      return HTTP.get('/components/uniPorts', {
        params: { contractId: contractId }
      })
        .then((response) => {
          this.availableUniPortId = response.data.serviceDataId;
        })
        .catch(() => {});
    },

    updateUpdatedMsanConfiguration() {
      if (
        this.contractId != null &&
        this.selectedONTDevice != null &&
        this.selectedUniPort != null
      ) {
        this.fetchMsan(
          this.contractId,
          this.selectedUniPort.serviceDataId,
          this.selectedONTDevice.serviceDataId
        );
        this.retrieveProvisioningInformation(
          this.contractId,
          this.selectedUniPort.serviceDataId
        );
      } else {
        this.updatedMsanConfiguration = null;
      }
    },
    retrieveProvisioningInformation: function (contractId, uniPortId) {
      return HTTP.get(`/provisioning/information/msan/${contractId}`, {
        params: {
          uniPortId: uniPortId
        }
      }).then((response) => {
        this.msanProvisioningInformation = response.data;
      });
    },
    fetchMsan(contractId, uniPortId, ontDeviceId) {
      this.updatedMsanConfigurationLoading = true;
      HTTP.get(
        `/components/msanData?contractId=${contractId}&uniPortId=${uniPortId}&ontDeviceId=${ontDeviceId}`
      )
        .then((response) => {
          this.updatedMsanConfiguration = response.data;
        })
        .catch((e) => e)
        .finally(() => (this.updatedMsanConfigurationLoading = false));
    },
    pushData() {
      this.$emit('selectionChanged', {
        selectedOntProduct: this.ontProductCanBeSelected
          ? this.selectedOntProduct
          : null,
        selectedONTDevice: this.selectedONTDevice,
        selectedUniPort: this.selectedUniPort,
        validationErrors: this.validateSelection()
      });
    },
    validateSelection() {
      var errorMessages = [];
      if (
        (this.selectedOntProduct === null ||
          this.selectedOntProduct === undefined) &&
        this.ontProductCanBeSelected
      ) {
        errorMessages.push('Es muss ein ONT-Produkt ausgewählt werden.');
      }
      if (
        this.selectedONTDevice === null ||
        this.selectedONTDevice === undefined
      ) {
        errorMessages.push('Es muss ein ONT-Gerät ausgewählt werden.');
      }
      if (this.selectedUniPort === null || this.selectedUniPort === undefined) {
        errorMessages.push('Es muss ein UNI-Port ausgewählt werden.');
      }
      return errorMessages;
    }
  }
};
</script>
