<template>
  <div>
    <DisplayContainer label="Vertragsinformationen">
      <ContractInformation
        class="margin-bottom-1"
        :contractId="contractData.contractInformation.contractId"
        :customerId="contractData.contractInformation.customerId"
        :activationDateOfContract="
          contractData.contractInformation.activationDateOfContract
        "
        :deactivationDateOfContract="
          contractData.contractInformation.deactivationDateOfContract
        "
        :address="contractData.contractInformation.installationAddress"
      />
      <AllOrderedProductsTable :allProducts="contractData.allProducts" />
    </DisplayContainer>

    <!-- services section -->
    <DisplayContainer label="Diensteauswahl">
      <DisplayContainer
        label="Provisionierung MSAN"
        :isSubcomponent="true"
        :selectionConfiguration="{
          label: 'MSAN provisionieren',
          isSelected: selectedProvisioningSettings.provisionMsanForInternet,
          disabled:
            disabled ||
            contractData.msanProvisioningInformation.msanErrorMessage != null
        }"
        @update:isSelected="
          {
            selectedProvisioningSettings.provisionMsanForInternet = $event;
            if ($event === false) {
              selectedProvisioningSettings.ontProduct = null;
              selectedProvisioningSettings.cpeSelection = null;
              selectedProvisioningSettings.ontSelection = null;
            } else {
              if (!presentONTProduct) {
                $refs.ontProductSelection.resetInput();
              }
              $refs.ontDeviceSelection.resetInput();
              if (
                contractData.msanProvisioningInformation.cpeProductWithDevice
              ) {
                $refs.cpeDeviceSelection.resetInput();
              }
            }
          }
          selectionChanged();
        "
      >
        <BlockerDisplay
          v-if="contractData.msanProvisioningInformation.msanErrorMessage"
          class="margin-top-0_5"
          :message="
            buildErrorMessageMsanProvisioning(
              contractData.msanProvisioningInformation.msanErrorMessage
            )
          "
        />
        <div class="input-row">
          <DataDisplay label="GPON-Hierarchie">
            <div
              v-if="
                contractData &&
                  contractData.msanProvisioningInformation.uniPortInformation
              "
            >
              <div>
                {{
                  contractData.msanProvisioningInformation.uniPortInformation
                    .namePointOfPresence
                }}
              </div>
              <div>
                {{
                  contractData.msanProvisioningInformation.uniPortInformation
                    .gponHierarchy
                }}
              </div>
            </div>
          </DataDisplay>
          <MsanProvisioningInformation
            :provisioningInformation="
              contractData.msanProvisioningInformation.provisioningInformation
            "
          />
        </div>
        <HardwareDeviceProductWithDevice
          productType="ONT"
          :productWithDevice="
            contractData.msanProvisioningInformation.ontProductWithDevice
          "
        />
        <HardwareDeviceProductWithDevice
          productType="CPE"
          :productWithDevice="
            contractData.msanProvisioningInformation.cpeProductWithDevice
          "
        />
        <DisplayContainer
          v-if="!presentONTProduct"
          label="ONT-Produkt *"
          :isSubcomponent="true"
        >
          <ProductSelection
            ref="ontProductSelection"
            class="margin-top-0_5"
            productType="ONT"
            :isRequired="
              selectedProvisioningSettings.provisionMsanForInternet === true ||
                (selectedProvisioningSettings.ontSelection != null &&
                  selectedProvisioningSettings.ontSelection != undefined)
            "
            :disabled="
              disabled ||
                (contractData.msanProvisioningInformation.msanErrorMessage !==
                  null &&
                  contractData.msanProvisioningInformation.msanErrorMessage !==
                  undefined) ||
                selectedProvisioningSettings.provisionMsanForInternet == false
            "
            :presentProductData="presentONTProduct"
            :previouslySelectedProduct="selectedProvisioningSettings.ontProduct"
            @selectedProductChanged="
              selectedProvisioningSettings.ontProduct = $event;
              selectedONTProduct = $event;
              if (
                selectedProvisioningSettings.ontProduct === null ||
                selectedProvisioningSettings.ontProduct === undefined
              ) {
                selectedProvisioningSettings.ontSelection = null;
              }
              selectionChanged();
            "
          />
        </DisplayContainer>
        <DisplayContainer label="ONT-Gerät" :isSubcomponent="true">
          <HardwareDeviceSelection
            ref="ontDeviceSelection"
            class="margin-top-0_5"
            :disabled="
              disabled ||
                (contractData.msanProvisioningInformation.msanErrorMessage !==
                  null &&
                  contractData.msanProvisioningInformation.msanErrorMessage !==
                  undefined) ||
                selectedProvisioningSettings.provisionMsanForInternet == false
            "
            :isRequired="false"
            :presentDeviceData="presentONTDevice"
            :previouslySelectedDevice="
              selectedProvisioningSettings.ontSelection
            "
            :deviceType="ontDeviceType"
            @selectedDeviceChanged="
              selectedProvisioningSettings.ontSelection = $event;
              selectionChanged();
            "
          />
        </DisplayContainer>
        <DisplayContainer
          v-if="contractData.msanProvisioningInformation.cpeProductWithDevice"
          label="Routerauswahl"
          :isSubcomponent="true"
        >
          <HardwareDeviceSelection
            ref="cpeDeviceSelection"
            class="margin-top-0_5"
            :disabled="
              disabled ||
                (contractData.msanProvisioningInformation.msanErrorMessage !==
                  null &&
                  contractData.msanProvisioningInformation.msanErrorMessage !==
                  undefined) ||
                selectedProvisioningSettings.provisionMsanForInternet == false
            "
            :presentDeviceData="presentCPEDevice"
            :previouslySelectedDevice="
              selectedProvisioningSettings.cpeSelection
            "
            :deviceType="
              contractData.msanProvisioningInformation.cpeProductWithDevice
                .deviceType
            "
            @selectedDeviceChanged="
              selectedProvisioningSettings.cpeSelection = $event;
              selectionChanged();
            "
          />
        </DisplayContainer>
      </DisplayContainer>
      <DisplayContainer
        label="Provisionierung Internet"
        :isSubcomponent="true"
        :selectionConfiguration="{
          label: 'Internet provisionieren',
          isSelected: selectedProvisioningSettings.provisionInternet,
          disabled:
            disabled ||
            contractData.internetProvisioningInformation
              .provisioningErrorMessage != null
        }"
        @update:isSelected="
          selectedProvisioningSettings.provisionInternet = $event;
          selectionChanged();
        "
      >
        <BlockerDisplay
          v-if="
            contractData.internetProvisioningInformation
              .provisioningErrorMessage
          "
          class="margin-top-0_5"
          :message="
            buildErrorMessageInternetProvisioning(
              contractData.internetProvisioningInformation
                .provisioningErrorMessage
            )
          "
        />
        <div class="input-row">
          <DataDisplay label="Nutzername Radius">
            <div
              v-if="
                contractData &&
                  contractData.internetProvisioningInformation &&
                  contractData.internetProvisioningInformation
                    .provisioningInformation.userName
              "
            >
              {{
                contractData.internetProvisioningInformation
                  .provisioningInformation.userName
              }}
            </div>
          </DataDisplay>
          <DataDisplay label="Internet-Hauptprodukt">
            <div
              v-if="
                contractData &&
                  contractData.internetProvisioningInformation &&
                  contractData.internetProvisioningInformation.internetMainProduct
              "
            >
              {{
                contractData.internetProvisioningInformation.internetMainProduct
                  .name
              }}
            </div>
          </DataDisplay>
          <DataDisplay label="Letzte Einwahl Router">
            <div
              v-if="
                contractData && contractData.internetProvisioningInformation
              "
            >
              <div
                v-if="
                  contractData.internetProvisioningInformation
                    .provisioningInformation.lastSessionRouter
                "
                class="icon-alligned-with-text"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">
                      mdi-web-check
                    </v-icon>
                  </template>
                  <span>
                    Zuletzt online am
                    {{
                      formatDateTime(
                        contractData.internetProvisioningInformation
                          .provisioningInformation.lastSessionRouter
                      )
                    }}
                  </span>
                </v-tooltip>
                &nbsp;
                {{
                  formatDateTime(
                    contractData.internetProvisioningInformation
                      .provisioningInformation.lastSessionRouter
                  )
                }}
              </div>
              <div v-else class="icon-alligned-with-text">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">
                      mdi-web-off
                    </v-icon>
                  </template>
                  <span> Noch nicht online</span>
                </v-tooltip>
                &nbsp; Offline
              </div>
            </div>
          </DataDisplay>
          <div></div>
        </div>
      </DisplayContainer>
      <DisplayContainer
        label="Provisionierung neuer Rufnummern"
        :isSubcomponent="true"
        :selectionConfiguration="{
          label: 'Neue Rufnummern provisionieren',
          isSelected: selectedProvisioningSettings.provisionNewPhoneNumbers,
          isFoldable: false,
          disabled: disabled
        }"
        @update:isSelected="
          {
            selectedProvisioningSettings.provisionNewPhoneNumbers = $event;
            selectionChanged();
          }
        "
      >
        <div class="input-row margin-top-1">
          <v-select
            v-model="selectedProvisioningSettings.numberRequestPhoneNumbers"
            :items="numberProvisionablePhoneNumbers"
            :disabled="
              disabled ||
                selectedProvisioningSettings.provisionNewPhoneNumbers == false
            "
            dense
            label="Anzahl neue Rufnummern"
          >
            <template v-slot:item="data">
              <div class="label">
                {{ data.item }}
              </div>
            </template>
            <template v-slot:selection="data">
              <div class="label" style="font-size: 1.1em">
                {{ data.item }}
              </div>
            </template>
          </v-select>
        </div>
      </DisplayContainer>
      <DisplayContainer
        label="Provisionierung bestehender Rufnummern"
        class="margin-bottom-1"
        :isSubcomponent="true"
        :selectionConfiguration="{
          label: 'Bestehende Rufnummern provisionieren',
          isSelected: selectedProvisioningSettings.provisionPhoneNumbers,
          isFoldable: false,
          disabled:
            disabled ||
            contractData.telephoneProvisioningInformation
              .provisioningErrorMessage != null
        }"
        @update:isSelected="
          {
            selectedProvisioningSettings.provisionPhoneNumbers = $event;
            if (selectedProvisioningSettings.provisionPhoneNumbers === false) {
              selectedProvisioningSettings.phoneNumbersForProvisioning = [];
            }
            selectionChanged();
          }
        "
      >
        <BlockerDisplay
          v-if="
            contractData.telephoneProvisioningInformation
              .provisioningErrorMessage
          "
          class="margin-top-0_5"
          :message="
            buildErrorMessagePhoneProvisioning(
              contractData.telephoneProvisioningInformation
                .provisioningErrorMessage
            )
          "
        />

        <v-data-table
          v-model="selectedProvisioningSettings.phoneNumbersForProvisioning"
          :headers="[
            {
              text: 'Telefonnummer',
              align: 'start',
              value: 'phoneNumber'
            },
            {
              text: 'Verfügbarkeit auf dem Softswitch',
              value: 'provisioningInformation.isAlreadyProvisioned'
            },
            {
              text: 'Letzte Aktivität',
              value: 'provisioningInformation.startDateCurrentVoIpSession'
            }
          ]"
          :items="
            contractData.telephoneProvisioningInformation
              .phoneNumbersAvailableForProvisioning
          "
          item-key="phoneNumber"
          show-select
          dense
          hide-default-footer
          class="elevation-1 margin-top-1"
          @item-selected="selectionChanged()"
        >
          <template v-slot:header.data-table-select="{}">
          </template>
          <template
            v-slot:item.data-table-select="{ item, isSelected, select }"
          >
            <div class="align-elements">
              <div class="v-simple-checkbox">
                <v-simple-checkbox
                  :value="isSelected"
                  :disabled="
                    disabled ||
                      selectedProvisioningSettings.provisionPhoneNumbers ===
                      false ||
                      (item.validationErrors !== null &&
                        item.validationErrors !== undefined &&
                        item.validationErrors.length > 0)
                  "
                  @input="select($event)"
                >
                </v-simple-checkbox>
              </div>
              <div
                v-if="
                  item.validationErrors !== null &&
                    item.validationErrors !== undefined &&
                    item.validationErrors.length > 0
                "
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="yellow"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-alert-outline
                    </v-icon>
                  </template>
                  <div
                    v-for="(error, index) in item.validationErrors"
                    :key="`error-${index}`"
                  >
                    <span>{{ buildErrorMessagePhoneNumber(error) }}</span>
                  </div>
                </v-tooltip>
              </div>
            </div>
          </template>
          <template
            v-slot:item.provisioningInformation.startDateCurrentVoIpSession="{
              item
            }"
          >
            <div
              v-if="item.provisioningInformation.startDateCurrentVoIpSession"
            >
              {{
                formatDate(
                  item.provisioningInformation.startDateCurrentVoIpSession
                )
              }}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template
            v-slot:item.provisioningInformation.isAlreadyProvisioned="{ item }"
          >
            <div v-if="item.provisioningInformation.isAlreadyProvisioned">
              Provisioniert
            </div>
            <div v-else>
              Verfügbar für Provisionierung
            </div>
          </template>
          <template v-slot:item.phoneNumber="{ item }">
            {{ item.phoneNumber }}
          </template>
        </v-data-table>
      </DisplayContainer>
      <DisplayContainer
        label="Portierung"
        :isSubcomponent="true"
        :selectionConfiguration="{
          label: 'Portierung durchführen',
          isSelected: selectedProvisioningSettings.portPhoneNumbers,
          isFoldable: false,
          disabled: disabled
        }"
        @update:isSelected="
          selectedProvisioningSettings.portPhoneNumbers = $event;
          selectionChanged();
        "
      >
        <DataDisplay label="Rufnummern zur Auswahl">
          <div
            v-if="
              contractData &&
                contractData.phoneNumbersAvailableForPorting &&
                contractData.phoneNumbersAvailableForPorting.length > 0
            "
          >
            <div
              v-for="(
                phoneNumber, index
              ) in contractData.phoneNumbersAvailableForPorting"
              :key="index"
            >
              {{ phoneNumber }}
            </div>
          </div>
        </DataDisplay>
      </DisplayContainer>
    </DisplayContainer>
    <DisplayContainer :label="'Anschaltdatum *'">
      <DatePicker
        :disabled="disabled"
        :errorMessage="dateErrorMessage"
        :minDate="minDate"
        :value="selectedProvisioningSettings.provisioningDate"
        disableWeekend
        disableHolidays
        label="Anschaltdatum"
        @input="
          {
            selectedProvisioningSettings.provisioningDate = $event;
            selectionChanged();
          }
        "
      />
    </DisplayContainer>
  </div>
</template>

<script>
import DatePicker from '@/components/elements/DatePicker.vue';
import { LocalDate, LocalDateTime, DateTimeFormatter } from '@js-joda/core';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';
import MsanProvisioningInformation from '@/components/deviceProvisioning/MsanProvisioningInformation.vue';
import ContractInformation from '@/components/ContractInformation.vue';
import HardwareDeviceProductWithDevice from '@/components/deviceProvisioning/HardwareDeviceProductWithDevice.vue';
import AllOrderedProductsTable from '@/components/AllOrderedProductsTable.vue';
import ProductSelection from '@/components/deviceProvisioning/ProductSelection.vue';
import HardwareDeviceSelection from '@/components/deviceProvisioning/HardwareDeviceSelection.vue';

import BlockerDisplay from './components/BlockerDisplay.vue';

import TimeUtility from '@/util/TimeUtility.js';

export default {
  name: 'ProvisioningData',
  components: {
    DatePicker,
    DisplayContainer,
    DataDisplay,
    HardwareDeviceSelection,
    ContractInformation,
    HardwareDeviceProductWithDevice,
    AllOrderedProductsTable,
    MsanProvisioningInformation,
    ProductSelection,
    BlockerDisplay
  },
  props: {
    contractData: {
      type: Object,
      required: true
    },
    previouslySelectedProvisioningSettings: {
      type: Object,
      required: false,
      default: null
    },
    disabled: { type: Boolean, required: false, default: false }
  },
  data: () => ({
    numberProvisionablePhoneNumbers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    numberProvisionablePhoneNumbersDisabled: [0],
    numberProvisionablePhoneNumbersEnabled: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    selectedProvisioningSettings: null,
    // Required because computed properties don't react to dynamically added properties.
    selectedONTProduct: null,
    presentONTProduct: null,
    presentONTDevice: null,
    presentCPEDevice: null,
    phoneNumbersAvailableForProvisioning: undefined
  }),
  computed: {
    minDate: function () {
      const minDate = LocalDate.now();
      return minDate.toString();
    },
    dateErrorMessage: function () {
      if (this.selectedProvisioningSettings.provisioningDate === null) {
        return 'Es muss ein Datum für die Provisionierung ausgewählt werden.';
      } else {
        return null;
      }
    },
    ontDeviceType: function () {
      if (this.selectedONTProduct) {
        return this.selectedONTProduct.requiredDevice;
      } else if (this.presentONTProduct) {
        return this.presentONTProduct.deviceType;
      } else {
        return null;
      }
    }
  },
  watch: {
    contractData: {
      handler: function (newValue, oldValue) {
        if (newValue && !this.selectedProvisioningSettings) {
          this.selectedProvisioningSettings =
            this.buildSelectedProvisioningSettings(newValue);
          this.selectionChanged();
        }
        if (newValue && JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          // TODO: Replace by lodash
          const contractData = JSON.parse(JSON.stringify(newValue));
          this.presentONTProduct = this.extractProductWithDeviceToProduct(
            contractData.msanProvisioningInformation.ontProductWithDevice
          );
          this.presentONTDevice = this.extractProductWithDeviceToDevice(
            contractData.msanProvisioningInformation.ontProductWithDevice
          );
          this.presentCPEDevice = this.extractProductWithDeviceToDevice(
            contractData.msanProvisioningInformation.cpeProductWithDevice
          );
        }
      },
      deep: true,
      immediate: true
    },
    previouslySelectedProvisioningSettings: {
      handler: function (newValue, oldValue) {
        if (
          !newValue &&
          !this.selectedProvisioningSettings &&
          this.contractData
        ) {
          this.selectedProvisioningSettings =
            this.buildSelectedProvisioningSettings(this.contractData);
          this.selectionChanged();
        } else if (
          newValue &&
          JSON.stringify(newValue) !== JSON.stringify(oldValue)
        ) {
          // TODO: Replace by lodash
          const selectedProvisioningSettings = JSON.parse(
            JSON.stringify(newValue)
          );
          this.selectedProvisioningSettings = selectedProvisioningSettings;
        }
      },
      deep: true,
      immediate: true
    },
    'selectedProvisioningSettings.provisionNewPhoneNumbers': {
      handler: function (newValue, _) {
        if (newValue === true) {
          this.numberProvisionablePhoneNumbers =
            this.numberProvisionablePhoneNumbersEnabled;
          if (
            this.selectedProvisioningSettings.numberRequestPhoneNumbers === 0
          ) {
            this.selectedProvisioningSettings.numberRequestPhoneNumbers = 1;
          }
        } else {
          this.numberProvisionablePhoneNumbers =
            this.numberProvisionablePhoneNumbersDisabled;
          if (this.selectedProvisioningSettings.numberRequestPhoneNumbers > 0) {
            this.selectedProvisioningSettings.numberRequestPhoneNumbers = 0;
          }
        }
        this.selectionChanged();
      },
      immediate: true
    }
    // selectedProvisioningSettings: {
    //   handler: function (newValue, _) {},
    //   deep: true,
    //   immediate: true
    // }
  },
  methods: {
    selectionChanged() {
      this.$emit('selectedProvisioningSettingsChanged', {
        selectedProvisioningSettings: this.selectedProvisioningSettings,
        isValid: this.validateSelectedProvisioningSettings(
          this.contractData,
          this.selectedProvisioningSettings
        )
      });
    },
    extractProductWithDeviceToDevice: (productWithDevice) => {
      if (productWithDevice && productWithDevice.device) {
        const cpeDevice = productWithDevice.device;
        return {
          serviceDataId: cpeDevice.serviceDataId,
          serialNumber: cpeDevice.serialNumber,
          model: cpeDevice.model,
          cpeDeviceType: cpeDevice.cpeDeviceType
        };
      } else {
        return null;
      }
    },
    extractProductWithDeviceToProduct: (productWithDevice) => {
      if (productWithDevice && productWithDevice.product) {
        return {
          id: productWithDevice.product.id,
          deviceType: productWithDevice.product.requiredDevice,
          name: productWithDevice.product.name
        };
      } else {
        return null;
      }
    },
    buildSelectedProvisioningSettings(contractData) {
      return {
        contractId: contractData.contractInformation.contractId,
        ontProduct: undefined,
        ontSelection: undefined,
        cpeSelection: undefined,
        provisionNewPhoneNumbers: false,
        provisionPhoneNumbers: false,
        numberRequestPhoneNumbers: 0,
        provisionInternet: false,
        provisionMsanForInternet: false,
        portPhoneNumbers: false,
        provisioningDate: null,
        phoneNumbersForProvisioning: []
      };
    },
    validateSelectedProvisioningSettings(contractData, selection) {
      if (!selection.provisioningDate) {
        return false;
      }
      if (selection.provisionMsanForInternet === true) {
        if (!selection.ontProduct) {
          return false;
        }
        if (
          (this.presentONTProduct || selection.ontSelection) &&
          !selection.ontProduct
        ) {
          return false;
        }
        if (this.presentCPEDevice && !selection.cpeSelection) {
          return false;
        }
        if (this.presentONTDevice && !selection.ontSelection) {
          return false;
        }
      }
      if (
        selection.provisionNewPhoneNumbers &&
        selection.numberRequestPhoneNumbers < 1
      ) {
        return false;
      }
      if (
        selection.provisionPhoneNumbers &&
        selection.phoneNumbersForProvisioning === []
      ) {
        return false;
      }
      return (
        selection.provisionMsanForInternet ||
        selection.provisionNewPhoneNumbers ||
        selection.provisionPhoneNumbers ||
        selection.provisionInternet ||
        selection.portPhoneNumbers
      );
    },
    buildErrorMessageMsanProvisioning(errorEnum) {
      if (errorEnum === 'MSAN_IS_ALREADY_PROVISIONED') {
        return 'Für diesen Vertrag wurde der MSAN bereits provisioniert.';
      } else if (errorEnum === 'ROUTER_WAS_ALREADY_ONLINE') {
        return 'Der Router war bereits online. Eine Provisionierung ist daher nicht mehr möglich. Bitte tragen Sie die fehlenden MSAN-Ressourcen in Dimari nach!';
      } else if (errorEnum === 'USERNAME_RADIUS_COULD_NOT_BE_DETERMINED') {
        return 'Der Nutzername in RADIUS konnte nicht ermittelt werden.';
      } else if (errorEnum === 'NO_INTERNET_MAIN_PRODUCT') {
        return 'Kein Internet-Hauptprodukt vorhanden.';
      } else if (errorEnum === 'MULTIPLE_INTERNET_MAIN_PRODUCTS') {
        return 'Im Vertrag sind mehrere nicht deaktivierte Hauptprodukte enthalten.';
      } else {
        return '';
      }
    },
    buildErrorMessageInternetProvisioning(errorEnum) {
      if (errorEnum === 'NO_INTERNET_MAIN_PRODUCT') {
        return 'Für diesen Vertrag gibt es kein Internethauptprodukt.';
      } else if (errorEnum === 'INTERNET_IS_ALREADY_PROVISIONED') {
        return 'Für diesen Vertrag wurden das Internet bereits provisioniert.';
      } else if (errorEnum === 'USERNAME_RADIUS_COULD_NOT_BE_DETERMINED') {
        return 'Der Nutzername in RADIUS konnte nicht ermittelt werden.';
      } else {
        return '';
      }
    },
    buildErrorMessagePhoneProvisioning(errorEnum) {
      if (errorEnum === 'NO_PHONE_NUMBERS_AVAILABLE') {
        return 'Für diesen Vertrag sind keine Telefonnummern verfügbar, die provisioniert werden könnten.';
      } else if (errorEnum === 'NO_PHONE_NUMBERS_CAN_BE_PROVISIONED') {
        return 'Keine der Rufnummern kann provisioniert werden.';
      } else {
        return '';
      }
    },
    buildErrorMessagePhoneNumber(validationError) {
      const errorCode = validationError.code;
      if (errorCode === 'PHONE_NUMBER_IS_ALREADY_PROVISIONED') {
        return 'Rufnummer wurde bereits provisioniert.';
      } else if (errorCode === 'PHONE_NUMBER_HAS_INVALID_FORMAT') {
        return 'Rufnummer hat ein ungültiges Format.';
      } else {
        return '';
      }
    },
    formatDateTime(date) {
      return TimeUtility.formatDateTime(date);
    },
    formatDate(date) {
      return LocalDateTime.parse(date, TimeUtility.dateFormatter).format(
        DateTimeFormatter.ofPattern('dd.MM.yyy')
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.v-simple-checkbox ::v-deep .v-input--selection-controls__input {
  margin-right: 0px;
}
.align-elements {
  display: flex;
  align-items: center;
}
.icon-alligned-with-text {
  display: flex;
  align-items: center;
}
</style>
