<template>
  <div>
    <v-alert
      dense
      outlined
      type="error"
      icon="mdi-lock"
    >
      {{ message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'BlockerDisplay',
  props: {
    message: { type: String, required: true }
  }
};
</script>

<style scoped lang="scss"></style>
