<template>
  <div>
    <DisplayContainer label="Vertragsinformationen">
      <ContractInformation
        class="margin-bottom-1"
        :contractId="contractData.contractInformation.contractId"
        :customerId="contractData.contractInformation.customerId"
        :activationDateOfContract="
          contractData.contractInformation.activationDateOfContract
        "
        :deactivationDateOfContract="
          contractData.contractInformation.deactivationDateOfContract
        "
        :address="contractData.contractInformation.installationAddress"
      />
      <AllOrderedProductsTable :allProducts="contractData.allProducts" />
    </DisplayContainer>
    <DisplayContainer label="Diensteauswahl">
      <DisplayContainer label="TV Produkt" :isSubcomponent="true">
        <div class="input-row margin-top-1">
          <v-select
            v-model="selectedProvisioningSettings.tvHauptProduct"
            :items="tvHauptProducts"
            dense
            item-text="name"
            item-value="id"
            return-object
            label="Hauptprodukt"
            placeholder="Produkt auswählen"
            persistent-placeholder
            :disabled="disabled"
          >
          </v-select>
        </div>
      </DisplayContainer>
      <DisplayContainer label="Gerät" :isSubcomponent="true">
        <div class="input-row margin-top-1">
          <v-select
            v-model="selectedProvisioningSettings.stbProduct"
            :items="setTopBoxes"
            dense
            item-text="name"
            item-value="id"
            label="Gerät"
            placeholder="Gerät auswählen"
            persistent-placeholder
            return-object
            :disabled="disabled"
            @input="selectedProvisioningSettings.stickNotSelected = false"
          >
          </v-select>
          <v-select
            v-model="selectedProvisioningSettings.numberOfSticks"
            :items="numberOfSetTopBoxes"
            dense
            label="Anzahl"
            :disabled="
              disabled || selectedProvisioningSettings.stickNotSelected
            "
          >
            <template v-slot:item="data">
              <div class="label">
                {{ data.item }}
              </div>
            </template>
            <template v-slot:selection="data">
              <div class="label" style="font-size: 1.1em">
                {{ data.item }}
              </div>
            </template>
          </v-select>
        </div>
      </DisplayContainer>
    </DisplayContainer>
    <DisplayContainer :label="'Schalttermin'">
      <div class="input-row margin-top-1">
        <DatePicker
          :disabled="disabled"
          :minDate="minDate"
          :value="selectedProvisioningSettings.setupDate"
          disableWeekend
          disableHolidays
          label="Schalttermin"
          @input="selectedProvisioningSettings.setupDate = $event"
        />
        <DataDisplay label="Letzte Einwahl Router">
          <div
            v-if="contractData && contractData.internetProvisioningInformation"
          >
            <div
              v-if="
                contractData.internetProvisioningInformation
                  .provisioningInformation.lastSession
              "
              class="icon-alligned-with-text icon-space"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-web-check
                  </v-icon>
                </template>
                <span>
                  Zuletzt online am
                  {{
                    formatZonedDateTime(
                      contractData.internetProvisioningInformation
                        .provisioningInformation.lastSession.acctStartTime
                    )
                  }}
                </span>
              </v-tooltip>
              &nbsp;
              {{
                formatZonedDateTime(
                  contractData.internetProvisioningInformation
                    .provisioningInformation.lastSession.acctStartTime
                )
              }}
            </div>
            <div v-else class="icon-alligned-with-text">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-web-off
                  </v-icon>
                </template>
                <span> Noch nicht online</span>
              </v-tooltip>
              &nbsp; <span>Offline</span>
              <span
                class="warning"
              >Die TV-Provisionierung wird erst dann durchgeführt, wenn die
                Internet-Provisionierung abgeschlossen ist!</span>
            </div>
          </div>
        </DataDisplay>
      </div>
    </DisplayContainer>
  </div>
</template>
<script>
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import ContractInformation from '@/components/ContractInformation.vue';
import AllOrderedProductsTable from '@/components/AllOrderedProductsTable.vue';
import DatePicker from '@/components/elements/DatePicker.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';
import { LocalDate } from '@js-joda/core';
import { HTTP } from '@/main/httpClient.js';
import TimeUtility from '@/util/TimeUtility.js';

export default {
  name: 'ProvisioningOptions',
  components: {
    DisplayContainer,
    ContractInformation,
    AllOrderedProductsTable,
    DatePicker,
    DataDisplay
  },
  props: {
    contractData: {
      type: Object,
      required: true
    },
    previouslySelectedProvisioningSettings: {
      type: Object,
      required: false,
      default: null
    },
    disabled: { type: Boolean, required: false, default: false }
  },
  data: () => ({
    numberOfSetTopBoxes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    tvHauptProducts: [],
    setTopBoxes: [],
    selectedProvisioningSettings: null
  }),
  computed: {
    minDate() {
      const minDate = LocalDate.now();
      return minDate.toString();
    }
  },
  watch: {
    contractData: {
      handler: function (newValue, oldValue) {
        if (newValue && !this.selectedProvisioningSettings) {
          this.selectedProvisioningSettings =
            this.buildSelectedProvisioningSettings(newValue);
        }
      },
      deep: true,
      immediate: true
    },
    selectedProvisioningSettings: {
      handler: function (newValue, _) {
        this.$emit('selectedProvisioningSettingsChanged', {
          selectedProvisioningSettings: newValue,
          isValid: this.validateSelectedProvisioningSettings(
            this.contractData,
            newValue
          )
        });
      },
      deep: true,
      immediate: true
    },
    previouslySelectedProvisioningSettings: {
      handler: function (newValue, oldValue) {
        if (
          !newValue &&
          !this.selectedProvisioningSettings &&
          this.contractData
        ) {
          this.selectedProvisioningSettings =
            this.buildSelectedProvisioningSettings(this.contractData);
        } else if (
          newValue &&
          JSON.stringify(newValue) !== JSON.stringify(oldValue)
        ) {
          const selectedProvisioningSettings = JSON.parse(
            JSON.stringify(newValue)
          );
          this.selectedProvisioningSettings = selectedProvisioningSettings;
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.loadTvProducts();
    this.loadSettopBoxes();
  },
  methods: {
    buildSelectedProvisioningSettings(contractData) {
      return {
        contractId: contractData.contractInformation.contractId,
        customerId: contractData.contractInformation.customerId,
        numberOfSticks: 0,
        setupDate: null,
        tvHauptProduct: null,
        stbProduct: null,
        stickNotSelected: true
      };
    },
    loadTvProducts() {
      return HTTP.get('/products/television?command=TV-Haupt').then(
        (response) => {
          this.tvHauptProducts = response.data;
        }
      );
    },
    loadSettopBoxes() {
      return HTTP.get('/products/television?command=STB').then((response) => {
        this.setTopBoxes = response.data;
      });
    },
    validateSelectedProvisioningSettings(contractData, selection) {
      if (!selection.setupDate) {
        return false;
      }
      if (!selection.tvHauptProduct && !selection.stbProduct) {
        return false;
      }
      return true;
    },
    formatDateTime(date) {
      return TimeUtility.formatDateTime(date);
    },
    formatZonedDateTime(date) {
      return TimeUtility.formatDateTimeNoMillies(date);
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-alligned-with-text {
  display: flex;
  align-items: center;
}
.icon-space {
  padding-top: var(--goe-spacing-0_5);
}
.warning {
  font-size: var(--goe-fontSize-small);
  margin-left: var(--goe-spacing-0_5);
  padding-left: var(--goe-spacing-0_5);
}
</style>
