var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DisplayContainer',{attrs:{"label":"Vertragsinformationen"}},[_c('ContractInformation',{staticClass:"margin-bottom-1",attrs:{"contractId":_vm.contractData.contractInformation.contractId,"customerId":_vm.contractData.contractInformation.customerId,"activationDateOfContract":_vm.contractData.contractInformation.activationDateOfContract,"deactivationDateOfContract":_vm.contractData.contractInformation.deactivationDateOfContract,"address":_vm.contractData.contractInformation.installationAddress}}),_c('AllOrderedProductsTable',{attrs:{"allProducts":_vm.contractData.allProducts}})],1),_c('DisplayContainer',{attrs:{"label":"Diensteauswahl"}},[_c('DisplayContainer',{attrs:{"label":"Provisionierung MSAN","isSubcomponent":true,"selectionConfiguration":{
        label: 'MSAN provisionieren',
        isSelected: _vm.selectedProvisioningSettings.provisionMsanForInternet,
        disabled:
          _vm.disabled ||
          _vm.contractData.msanProvisioningInformation.msanErrorMessage != null
      }},on:{"update:isSelected":function($event){{
          _vm.selectedProvisioningSettings.provisionMsanForInternet = $event;
          if ($event === false) {
            _vm.selectedProvisioningSettings.ontProduct = null;
            _vm.selectedProvisioningSettings.cpeSelection = null;
            _vm.selectedProvisioningSettings.ontSelection = null;
          } else {
            if (!_vm.presentONTProduct) {
              _vm.$refs.ontProductSelection.resetInput();
            }
            _vm.$refs.ontDeviceSelection.resetInput();
            if (
              _vm.contractData.msanProvisioningInformation.cpeProductWithDevice
            ) {
              _vm.$refs.cpeDeviceSelection.resetInput();
            }
          }
        }
        _vm.selectionChanged();}}},[(_vm.contractData.msanProvisioningInformation.msanErrorMessage)?_c('BlockerDisplay',{staticClass:"margin-top-0_5",attrs:{"message":_vm.buildErrorMessageMsanProvisioning(
            _vm.contractData.msanProvisioningInformation.msanErrorMessage
          )}}):_vm._e(),_c('div',{staticClass:"input-row"},[_c('DataDisplay',{attrs:{"label":"GPON-Hierarchie"}},[(
              _vm.contractData &&
                _vm.contractData.msanProvisioningInformation.uniPortInformation
            )?_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.contractData.msanProvisioningInformation.uniPortInformation .namePointOfPresence)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.contractData.msanProvisioningInformation.uniPortInformation .gponHierarchy)+" ")])]):_vm._e()]),_c('MsanProvisioningInformation',{attrs:{"provisioningInformation":_vm.contractData.msanProvisioningInformation.provisioningInformation}})],1),_c('HardwareDeviceProductWithDevice',{attrs:{"productType":"ONT","productWithDevice":_vm.contractData.msanProvisioningInformation.ontProductWithDevice}}),_c('HardwareDeviceProductWithDevice',{attrs:{"productType":"CPE","productWithDevice":_vm.contractData.msanProvisioningInformation.cpeProductWithDevice}}),(!_vm.presentONTProduct)?_c('DisplayContainer',{attrs:{"label":"ONT-Produkt *","isSubcomponent":true}},[_c('ProductSelection',{ref:"ontProductSelection",staticClass:"margin-top-0_5",attrs:{"productType":"ONT","isRequired":_vm.selectedProvisioningSettings.provisionMsanForInternet === true ||
              (_vm.selectedProvisioningSettings.ontSelection != null &&
                _vm.selectedProvisioningSettings.ontSelection != undefined),"disabled":_vm.disabled ||
              (_vm.contractData.msanProvisioningInformation.msanErrorMessage !==
                null &&
                _vm.contractData.msanProvisioningInformation.msanErrorMessage !==
                undefined) ||
              _vm.selectedProvisioningSettings.provisionMsanForInternet == false,"presentProductData":_vm.presentONTProduct,"previouslySelectedProduct":_vm.selectedProvisioningSettings.ontProduct},on:{"selectedProductChanged":function($event){_vm.selectedProvisioningSettings.ontProduct = $event;
            _vm.selectedONTProduct = $event;
            if (
              _vm.selectedProvisioningSettings.ontProduct === null ||
              _vm.selectedProvisioningSettings.ontProduct === undefined
            ) {
              _vm.selectedProvisioningSettings.ontSelection = null;
            }
            _vm.selectionChanged();}}})],1):_vm._e(),_c('DisplayContainer',{attrs:{"label":"ONT-Gerät","isSubcomponent":true}},[_c('HardwareDeviceSelection',{ref:"ontDeviceSelection",staticClass:"margin-top-0_5",attrs:{"disabled":_vm.disabled ||
              (_vm.contractData.msanProvisioningInformation.msanErrorMessage !==
                null &&
                _vm.contractData.msanProvisioningInformation.msanErrorMessage !==
                undefined) ||
              _vm.selectedProvisioningSettings.provisionMsanForInternet == false,"isRequired":false,"presentDeviceData":_vm.presentONTDevice,"previouslySelectedDevice":_vm.selectedProvisioningSettings.ontSelection,"deviceType":_vm.ontDeviceType},on:{"selectedDeviceChanged":function($event){_vm.selectedProvisioningSettings.ontSelection = $event;
            _vm.selectionChanged();}}})],1),(_vm.contractData.msanProvisioningInformation.cpeProductWithDevice)?_c('DisplayContainer',{attrs:{"label":"Routerauswahl","isSubcomponent":true}},[_c('HardwareDeviceSelection',{ref:"cpeDeviceSelection",staticClass:"margin-top-0_5",attrs:{"disabled":_vm.disabled ||
              (_vm.contractData.msanProvisioningInformation.msanErrorMessage !==
                null &&
                _vm.contractData.msanProvisioningInformation.msanErrorMessage !==
                undefined) ||
              _vm.selectedProvisioningSettings.provisionMsanForInternet == false,"presentDeviceData":_vm.presentCPEDevice,"previouslySelectedDevice":_vm.selectedProvisioningSettings.cpeSelection,"deviceType":_vm.contractData.msanProvisioningInformation.cpeProductWithDevice
              .deviceType},on:{"selectedDeviceChanged":function($event){_vm.selectedProvisioningSettings.cpeSelection = $event;
            _vm.selectionChanged();}}})],1):_vm._e()],1),_c('DisplayContainer',{attrs:{"label":"Provisionierung Internet","isSubcomponent":true,"selectionConfiguration":{
        label: 'Internet provisionieren',
        isSelected: _vm.selectedProvisioningSettings.provisionInternet,
        disabled:
          _vm.disabled ||
          _vm.contractData.internetProvisioningInformation
            .provisioningErrorMessage != null
      }},on:{"update:isSelected":function($event){_vm.selectedProvisioningSettings.provisionInternet = $event;
        _vm.selectionChanged();}}},[(
          _vm.contractData.internetProvisioningInformation
            .provisioningErrorMessage
        )?_c('BlockerDisplay',{staticClass:"margin-top-0_5",attrs:{"message":_vm.buildErrorMessageInternetProvisioning(
            _vm.contractData.internetProvisioningInformation
              .provisioningErrorMessage
          )}}):_vm._e(),_c('div',{staticClass:"input-row"},[_c('DataDisplay',{attrs:{"label":"Nutzername Radius"}},[(
              _vm.contractData &&
                _vm.contractData.internetProvisioningInformation &&
                _vm.contractData.internetProvisioningInformation
                  .provisioningInformation.userName
            )?_c('div',[_vm._v(" "+_vm._s(_vm.contractData.internetProvisioningInformation .provisioningInformation.userName)+" ")]):_vm._e()]),_c('DataDisplay',{attrs:{"label":"Internet-Hauptprodukt"}},[(
              _vm.contractData &&
                _vm.contractData.internetProvisioningInformation &&
                _vm.contractData.internetProvisioningInformation.internetMainProduct
            )?_c('div',[_vm._v(" "+_vm._s(_vm.contractData.internetProvisioningInformation.internetMainProduct .name)+" ")]):_vm._e()]),_c('DataDisplay',{attrs:{"label":"Letzte Einwahl Router"}},[(
              _vm.contractData && _vm.contractData.internetProvisioningInformation
            )?_c('div',[(
                _vm.contractData.internetProvisioningInformation
                  .provisioningInformation.lastSessionRouter
              )?_c('div',{staticClass:"icon-alligned-with-text"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-web-check ")])]}}],null,false,3406274665)},[_c('span',[_vm._v(" Zuletzt online am "+_vm._s(_vm.formatDateTime( _vm.contractData.internetProvisioningInformation .provisioningInformation.lastSessionRouter ))+" ")])]),_vm._v("   "+_vm._s(_vm.formatDateTime( _vm.contractData.internetProvisioningInformation .provisioningInformation.lastSessionRouter ))+" ")],1):_c('div',{staticClass:"icon-alligned-with-text"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-web-off ")])]}}],null,false,259221984)},[_c('span',[_vm._v(" Noch nicht online")])]),_vm._v("   Offline ")],1)]):_vm._e()]),_c('div')],1)],1),_c('DisplayContainer',{attrs:{"label":"Provisionierung neuer Rufnummern","isSubcomponent":true,"selectionConfiguration":{
        label: 'Neue Rufnummern provisionieren',
        isSelected: _vm.selectedProvisioningSettings.provisionNewPhoneNumbers,
        isFoldable: false,
        disabled: _vm.disabled
      }},on:{"update:isSelected":function($event){{
          _vm.selectedProvisioningSettings.provisionNewPhoneNumbers = $event;
          _vm.selectionChanged();
        }}}},[_c('div',{staticClass:"input-row margin-top-1"},[_c('v-select',{attrs:{"items":_vm.numberProvisionablePhoneNumbers,"disabled":_vm.disabled ||
              _vm.selectedProvisioningSettings.provisionNewPhoneNumbers == false,"dense":"","label":"Anzahl neue Rufnummern"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(data.item)+" ")])]}},{key:"selection",fn:function(data){return [_c('div',{staticClass:"label",staticStyle:{"font-size":"1.1em"}},[_vm._v(" "+_vm._s(data.item)+" ")])]}}]),model:{value:(_vm.selectedProvisioningSettings.numberRequestPhoneNumbers),callback:function ($$v) {_vm.$set(_vm.selectedProvisioningSettings, "numberRequestPhoneNumbers", $$v)},expression:"selectedProvisioningSettings.numberRequestPhoneNumbers"}})],1)]),_c('DisplayContainer',{staticClass:"margin-bottom-1",attrs:{"label":"Provisionierung bestehender Rufnummern","isSubcomponent":true,"selectionConfiguration":{
        label: 'Bestehende Rufnummern provisionieren',
        isSelected: _vm.selectedProvisioningSettings.provisionPhoneNumbers,
        isFoldable: false,
        disabled:
          _vm.disabled ||
          _vm.contractData.telephoneProvisioningInformation
            .provisioningErrorMessage != null
      }},on:{"update:isSelected":function($event){{
          _vm.selectedProvisioningSettings.provisionPhoneNumbers = $event;
          if (_vm.selectedProvisioningSettings.provisionPhoneNumbers === false) {
            _vm.selectedProvisioningSettings.phoneNumbersForProvisioning = [];
          }
          _vm.selectionChanged();
        }}}},[(
          _vm.contractData.telephoneProvisioningInformation
            .provisioningErrorMessage
        )?_c('BlockerDisplay',{staticClass:"margin-top-0_5",attrs:{"message":_vm.buildErrorMessagePhoneProvisioning(
            _vm.contractData.telephoneProvisioningInformation
              .provisioningErrorMessage
          )}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 margin-top-1",attrs:{"headers":[
          {
            text: 'Telefonnummer',
            align: 'start',
            value: 'phoneNumber'
          },
          {
            text: 'Verfügbarkeit auf dem Softswitch',
            value: 'provisioningInformation.isAlreadyProvisioned'
          },
          {
            text: 'Letzte Aktivität',
            value: 'provisioningInformation.startDateCurrentVoIpSession'
          }
        ],"items":_vm.contractData.telephoneProvisioningInformation
            .phoneNumbersAvailableForProvisioning,"item-key":"phoneNumber","show-select":"","dense":"","hide-default-footer":""},on:{"item-selected":function($event){return _vm.selectionChanged()}},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){return undefined}},{key:"item.data-table-select",fn:function(ref){
            var item = ref.item;
            var isSelected = ref.isSelected;
            var select = ref.select;
return [_c('div',{staticClass:"align-elements"},[_c('div',{staticClass:"v-simple-checkbox"},[_c('v-simple-checkbox',{attrs:{"value":isSelected,"disabled":_vm.disabled ||
                    _vm.selectedProvisioningSettings.provisionPhoneNumbers ===
                    false ||
                    (item.validationErrors !== null &&
                      item.validationErrors !== undefined &&
                      item.validationErrors.length > 0)},on:{"input":function($event){return select($event)}}})],1),(
                item.validationErrors !== null &&
                  item.validationErrors !== undefined &&
                  item.validationErrors.length > 0
              )?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"yellow"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-outline ")])]}}],null,true)},_vm._l((item.validationErrors),function(error,index){return _c('div',{key:("error-" + index)},[_c('span',[_vm._v(_vm._s(_vm.buildErrorMessagePhoneNumber(error)))])])}),0)],1):_vm._e()])]}},{key:"item.provisioningInformation.startDateCurrentVoIpSession",fn:function(ref){
          var item = ref.item;
return [(item.provisioningInformation.startDateCurrentVoIpSession)?_c('div',[_vm._v(" "+_vm._s(_vm.formatDate( item.provisioningInformation.startDateCurrentVoIpSession ))+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"item.provisioningInformation.isAlreadyProvisioned",fn:function(ref){
          var item = ref.item;
return [(item.provisioningInformation.isAlreadyProvisioned)?_c('div',[_vm._v(" Provisioniert ")]):_c('div',[_vm._v(" Verfügbar für Provisionierung ")])]}},{key:"item.phoneNumber",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.phoneNumber)+" ")]}}]),model:{value:(_vm.selectedProvisioningSettings.phoneNumbersForProvisioning),callback:function ($$v) {_vm.$set(_vm.selectedProvisioningSettings, "phoneNumbersForProvisioning", $$v)},expression:"selectedProvisioningSettings.phoneNumbersForProvisioning"}})],1),_c('DisplayContainer',{attrs:{"label":"Portierung","isSubcomponent":true,"selectionConfiguration":{
        label: 'Portierung durchführen',
        isSelected: _vm.selectedProvisioningSettings.portPhoneNumbers,
        isFoldable: false,
        disabled: _vm.disabled
      }},on:{"update:isSelected":function($event){_vm.selectedProvisioningSettings.portPhoneNumbers = $event;
        _vm.selectionChanged();}}},[_c('DataDisplay',{attrs:{"label":"Rufnummern zur Auswahl"}},[(
            _vm.contractData &&
              _vm.contractData.phoneNumbersAvailableForPorting &&
              _vm.contractData.phoneNumbersAvailableForPorting.length > 0
          )?_c('div',_vm._l((_vm.contractData.phoneNumbersAvailableForPorting),function(phoneNumber,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(phoneNumber)+" ")])}),0):_vm._e()])],1)],1),_c('DisplayContainer',{attrs:{"label":'Anschaltdatum *'}},[_c('DatePicker',{attrs:{"disabled":_vm.disabled,"errorMessage":_vm.dateErrorMessage,"minDate":_vm.minDate,"value":_vm.selectedProvisioningSettings.provisioningDate,"disableWeekend":"","disableHolidays":"","label":"Anschaltdatum"},on:{"input":function($event){{
          _vm.selectedProvisioningSettings.provisioningDate = $event;
          _vm.selectionChanged();
        }}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }