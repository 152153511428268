<template>
  <div>
    <div class="displayLabel">
      {{ label }}
    </div>
    <DisplayContainer
      isSubcomponent
      label="Kontaktdaten"
      style="background-color: transparent"
    >
      <div class="input-row">
        <v-text-field
          ref="customerId"
          label="Kundennummer"
          persistent-placeholder
          clearable
          autofocus
          :value="currentState.customerId"
          @input="
            {
              updateState('customerId', $event);
              updateState('contractId', null);
              updateState('firstName', null);
              updateState('lastName', null);
              updateState('contactAddress', {});
            }
          "
          @keydown.enter="$emit('confirmed')"
        />
        <v-text-field
          ref="contactId"
          label="Vertragsnummer"
          persistent-placeholder
          clearable
          autofocus
          :value="currentState.contractId"
          @input="
            {
              updateState('contractId', $event);
              updateState('customerId', null);
              updateState('firstName', null);
              updateState('lastName', null);
              updateState('contactAddress', {});
            }
          "
          @keydown.enter="$emit('confirmed')"
        />
      </div>
      <div>
        <div class="input-row margin-bottom-1">
          <v-text-field
            :value="currentState.firstName"
            label="Vorname"
            dense
            :disabled="disabled"
            persistent-placeholder
            :error-messages="firstNameValidationError"
            @input="
              {
                updateState('firstName', $event);
                updateState('contractId', null);
                updateState('customerId', null);
                firstNameValidationError = validateFirstName($event);
              }
            "
            @keydown.enter="$emit('confirmed')"
          ></v-text-field>

          <v-text-field
            :value="currentState.lastName"
            label="Nachname"
            dense
            :disabled="disabled"
            persistent-placeholder
            :error-messages="lastNameValidationError"
            @input="
              {
                updateState('lastName', $event);
                updateState('contractId', null);
                updateState('customerId', null);
                lastNameValidationError = validateLastName($event);
              }
            "
            @keydown.enter="$emit('confirmed')"
          ></v-text-field>
        </div>
      </div>
    </DisplayContainer>

    <DisplayContainer
      isSubcomponent
      label="Adresse"
      style="background-color: transparent"
    >
      <ContractAddressSelection
        :state="currentState.contactAddress"
        class="margin-top-1"
        :disabled="disabled"
        @stateChanged="updateState('contactAddress', $event)"
        @confirmed="$emit('confirmed')"
      />
    </DisplayContainer>
  </div>
</template>

<script>
// import lodash from 'lodash';

import ContractAddressSelection from '@/components/ContractAddressSelection.vue';
import AddressAutocomplete from '@/components/orderEntry/orderCreation/customerDataSelection/AddressAutocomplete.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';

import { nameIsValid } from '@/util/ValidationUtil.js';

export default {
  name: 'WithdrawalSearchSelection',
  components: {
    AddressAutocomplete,
    ContractAddressSelection,
    DisplayContainer
  },
  props: {
    label: {
      type: String,
      required: true
    },
    state: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    customerSearchData: null,
    customerData: null,
    validationErrors: null,
    customerIdValidationError: null,
    contractValidationError: null,
    firstNameValidationError: null,
    lastNameValidationError: null
  }),
  computed: {
    currentState() {
      return { ...this.state };
    }
  },
  created() {},
  methods: {
    updateState: function (varName, varValue) {
      if (
        (varName === 'contractId' || varName === 'customerId') &&
        varValue !== null
      ) {
        varValue = varValue.trim();
        console.log(varValue);
      }
      this.currentState[varName] = varValue;
      this.$emit('stateChanged', this.currentState);
      this.validateInputs();
    },
    validateCustomerId() {
      let inputValid = true;
      if (!this.currentState.customerId) {
        inputValid = false;
        this.customerIdValidationError =
          'Es muss eine Kundennummer angegeben werden!';
      } else if (isNaN(this.currentState.customerId)) {
        inputValid = false;
        this.customerIdValidationError =
          'Die Kundennummer muss numerisch sein!';
      } else {
        this.customerIdValidationError = null;
      }
      return inputValid;
    },
    validateInputs() {
      this.validateCustomerId();
      const validationErrors = [];
      if (this.customerIdValidationError) {
        validationErrors.push(this.customerIdValidationError);
      }
      if (this.contractValidationError) {
        validationErrors.push(this.contractValidationError);
      }
      this.$emit('inputValidationChanged', validationErrors);
    },
    validateFirstName(firstName) {
      return nameIsValid(firstName)
        ? []
        : ['Der Vorname enthält ungültige Zeichen.'];
    },
    validateLastName(lastName) {
      return nameIsValid(lastName)
        ? []
        : ['Der Nachname enthält ungültige Zeichen.'];
    }
  }
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.displayLabel {
  color: var(--goe-fontColor-highlight);
}
.input-row-adress-data {
  display: grid;
  grid-template-columns: auto max-content max-content;
  grid-gap: var(--goe-spacing-1);
}
</style>
