<template>
  <div>
    <DisplayContainer :label="label">
      <div class="search-wrapper">
        <SearchContractSelection
          label=""
          :state="currentState.searchState"
          @stateChanged="updateSearchState($event)"
          @confirmed="retrieveCustomerContracts()"
        />
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              depressed
              v-bind="attrs"
              v-on="on"
              @click="retrieveCustomerContracts()"
            >
              Vertrag suchen
              <v-icon> mdi-magnify </v-icon>
            </v-btn>
          </template>
          Verträge suchen
        </v-tooltip>
      </div>

      <v-data-table
        v-if="currentState.customerContracts || contractDataLoading"
        v-model="selectedItemValue"
        :headers="listHeaders"
        :items="contracts"
        :items-per-page="10"
        item-key="contractId"
        selectable-key="contractId"
        show-select
        single-select
        no-data-text="Verträge wurden noch nicht geladen."
        class="elevation-1 search-list"
        :loading="contractDataLoading"
        loading-text="Laden ... Bitte warten"
        :server-items-length="tableRowCount"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50, -1]
        }"
        @item-selected="selectedItemChanged($event)"
        @click:row="rowClicked"
      >
        <template v-slot:[`footer.page-text`]>
          <v-switch
            v-model="showDeactivatedContracts"
            label="Deaktivierte Verträge anzeigen"
            class="pa-3"
          ></v-switch>
        </template>
        <template v-slot:[`item.compactAddress`]="{ item }">
          <div class="row">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-icon
                    v-if="item.addressIcon"
                    class="icon-light"
                  >
                    {{ item.addressIcon }}
                  </v-icon>
                </div>
              </template>
              <span>{{ item.addressType }}</span>
            </v-tooltip>
            <div class="label-address">
              {{ item.compactAddress }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.creationDate`]="{ item }">
          {{ formatDate(item.creationDate) }}
        </template>
        <template v-slot:[`item.dateDeactivated`]="{ item }">
          {{ formatDate(item.dateDeactivated) }}
        </template>
        <template v-slot:[`item.dateActivated`]="{ item }">
          {{ formatDate(item.dateActivated) }}
        </template>
      </v-data-table>
    </DisplayContainer>
  </div>
</template>

<script>
import SearchContractSelection from './SearchContractSelection.vue';
import Loader from '@/components/elements/Loader.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import { HTTP } from '@/main/httpClient.js';
import TimeUtility from '@/util/TimeUtility.js';
import { objectToQueryParamString } from '@/util/ObjectUtil.js';
import AddressUtil from '@/util/AddressUtil.js';

export default {
  name: 'SearchExtendedContractData',
  components: {
    SearchContractSelection,
    Loader,
    DisplayContainer,
    TimeUtility
  },
  props: {
    label: {
      type: String,
      required: true
    },
    state: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    customerData: null,
    contractDataLoading: false,
    customerIdValidationError: null,
    contractValidationError: null,
    showDeactivatedContracts: false,
    listHeaders: [
      { text: 'Vertragsnummer', value: 'contractId' },
      { text: 'Internet Produkt', value: 'productName' },
      { text: 'Kundennummer', value: 'customerId' },
      { text: 'Name / Firma', value: 'nameAndCompany' },
      { text: 'Erstellungsdatum', value: 'creationDate' },
      { text: 'Aktivierungsdatum', value: 'dateActivated' },
      { text: 'Deaktivierungsdatum', value: 'dateDeactivated' },
      { text: 'Adresse', value: 'compactAddress' }
    ],
    options: {},
    totalDataTableCount: 0
  }),
  computed: {
    currentState() {
      return { ...this.state };
    },
    tableRowCount() {
      if (this.currentState) {
        if (this.currentState.customerContracts) {
          if (this.currentState.customerContracts.result) {
            return this.currentState.customerContracts.result.filter(
              (contract) =>
                this.showDeactivatedContracts ||
                contract.dateDeactivated === null ||
                contract.dateDeactivated === ''
            ).length;
          }
        }
      }
      return 0;
    },
    contracts() {
      if (this.currentState) {
        if (this.currentState.customerContracts) {
          if (this.currentState.customerContracts.result) {
            return this.currentState.customerContracts.result
              .filter(
                (contract) =>
                  this.showDeactivatedContracts ||
                  contract.dateDeactivated === null ||
                  contract.dateDeactivated === ''
              )
              .map((item) => ({
                contractId: item.contractId,
                customerId: item.customerId,
                nameAndCompany: item?.customerAddress?.nameAndCompany || '',
                compactAddress:
                  AddressUtil.formatCompactAddress(item?.customerAddress) || '',
                creationDate: item.creationDate,
                confirmationDate: item.confirmationDate,
                dateDeactivated: item.dateDeactivated,
                dateActivated: item.dateActivated,
                productName: item?.internetMainProduct?.name || '',
                addressType: this.getAddressType(
                  item?.customerAddress?.addressType
                ),
                addressIcon: this.getAddressIcon(
                  item?.customerAddress?.addressType
                )
              }));
          }
        }
      }
      return undefined;
    },
    selectedItemValue: {
      get: function () {
        if (!this.currentState.selectedContract) return [];
        return [this.currentState.selectedContract];
      },
      set: function (newValue) {}
    }
  },
  watch: {
    options: {
      handler() {
        this.retrieveCustomerContracts();
      },
      deep: true
    }
  },
  mounted: function () {
    this.validateInputs();
  },

  methods: {
    getAddressType(item) {
      if (!item) return;
      if (item === 'INSTALLATION') return 'Installationadresse';
      else if (item === 'INVOICE') return 'Rechnungsadresse';
      else return item;
    },
    getAddressIcon(item) {
      if (!item) return;
      if (item === 'INVOICE') return 'mdi-home-account';
      else if (item === 'INSTALLATION') return 'mdi-home-analytics';
      else return 'mdi-home-alert';
    },
    rowClicked(item, row) {
      var rowSelect = !row.isSelected;
      row.select(rowSelect);

      var selectEvent = {
        item: item,
        value: rowSelect
      };

      this.selectedItemChanged(selectEvent);
    },
    selectedItemChanged(event) {
      const { item, value } = event;
      if (value) {
        this.updateState('selectedContract', item);
        this.$emit('inputValidationChanged', []);
      } else {
        this.updateState('selectedContract', null);
        this.$emit('inputValidationChanged', [this.customerIdValidationError]);
      }
    },
    getDataFromSearch(event) {
      console.log('Event: ' + event);
    },
    retrieveSearchData() {
      console.log('clicked Retrieve');
    },
    updateSearchState: function (varValue) {
      this.currentState.searchState = varValue;
      this.$emit('stateChanged', this.currentState);
      this.validateInputs();
    },
    updateState: function (varName, varValue) {
      this.currentState[varName] = varValue;
      this.$emit('stateChanged', this.currentState);
      this.validateInputs();
    },
    retrieveCustomerContracts() {
      let { page, itemsPerPage } = this.options;

      if (!page) page = 1;
      if (!itemsPerPage) itemsPerPage = 10;

      const queryString = objectToQueryParamString(
        this.currentState.searchState
      );
      this.updateState('selectedContract', null);
      this.updateState('customerContracts', {});
      if (queryString) {
        this.contractDataLoading = true;
        HTTP.get(
          `/contracts/?${queryString}&page=${page}&pageSize=${itemsPerPage}`
        )
          .then((res) => {
            this.updateState('customerContracts', res.data);
            this.totalDataTableCount = res.data.total;
          })
          .catch((e) => {})
          .finally(() => {
            this.contractDataLoading = false;
          });
      }
    },
    validateCustomerId() {
      let inputValid = true;
      if (!this.currentState.customerId) {
        inputValid = false;
        this.customerIdValidationError =
          'Es muss eine Kundennummer angegeben werden!';
      } else if (isNaN(this.currentState.customerId)) {
        inputValid = false;
        this.customerIdValidationError =
          'Die Kundennummer muss numerisch sein!';
      } else {
        this.customerIdValidationError = null;
      }
      return inputValid;
    },
    validateContract() {
      if (!this.currentState.selectedContract) {
        this.contractValidationError = 'Es muss ein Vertrag ausgewählt werden!';
        return false;
      } else {
        this.contractValidationError = null;
        return true;
      }
    },
    validateInputs() {
      this.validateContract();
      const validationErrors = [];
      if (this.customerIdValidationError) {
        validationErrors.push(this.customerIdValidationError);
      }
      if (this.contractValidationError) {
        validationErrors.push(this.contractValidationError);
      }
      this.$emit('inputValidationChanged', validationErrors);
    },
    getContractTypeString(contractType) {
      if (contractType === 'SERVICE_ORDER') {
        return 'Dienstauftrag';
      } else if (contractType === 'HOUSE_CONNECTION_ORDER') {
        return 'Hausanschlussauftrag';
      } else {
        return 'Vertragstyp unbekannt';
      }
    },
    formatDate(date) {
      return date ? TimeUtility.formatLocalDate(date) : '-';
    }
  }
};
</script>

<style scoped>
.loader-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loader {
  margin: auto;
  top: calc(50% - var(--goe-spacing-2));
  position: initial;
}
.input-with-button {
  display: flex;
}
.input-with-button .v-btn {
  align-self: center;
  margin-left: var(--goe-spacing-1);
}

.search-wrapper .v-btn {
  float: right;
}

.search-list {
  margin-top: 10px;
}

.table-item-count-wrapper {
  width: 100%;
}

.table-item-count {
  float: right;
  margin-right: 88px;
}
.icon-light {
  color: var(--goe-fontColor-highlight);
}
.label-address {
  margin-top: 3px;
}
</style>
