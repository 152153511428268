var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"displayLabel"},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('DisplayContainer',{staticStyle:{"background-color":"transparent"},attrs:{"isSubcomponent":"","label":"Kontaktdaten"}},[_c('div',{staticClass:"input-row"},[_c('v-text-field',{ref:"customerId",attrs:{"label":"Kundennummer","persistent-placeholder":"","clearable":"","autofocus":"","value":_vm.currentState.customerId},on:{"input":function($event){{
            _vm.updateState('customerId', $event);
            _vm.updateState('contractId', null);
            _vm.updateState('firstName', null);
            _vm.updateState('lastName', null);
            _vm.updateState('contactAddress', {});
          }},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('confirmed')}}}),_c('v-text-field',{ref:"contactId",attrs:{"label":"Vertragsnummer","persistent-placeholder":"","clearable":"","autofocus":"","value":_vm.currentState.contractId},on:{"input":function($event){{
            _vm.updateState('contractId', $event);
            _vm.updateState('customerId', null);
            _vm.updateState('firstName', null);
            _vm.updateState('lastName', null);
            _vm.updateState('contactAddress', {});
          }},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('confirmed')}}})],1),_c('div',[_c('div',{staticClass:"input-row margin-bottom-1"},[_c('v-text-field',{attrs:{"value":_vm.currentState.firstName,"label":"Vorname","dense":"","disabled":_vm.disabled,"persistent-placeholder":"","error-messages":_vm.firstNameValidationError},on:{"input":function($event){{
              _vm.updateState('firstName', $event);
              _vm.updateState('contractId', null);
              _vm.updateState('customerId', null);
              _vm.firstNameValidationError = _vm.validateFirstName($event);
            }},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('confirmed')}}}),_c('v-text-field',{attrs:{"value":_vm.currentState.lastName,"label":"Nachname","dense":"","disabled":_vm.disabled,"persistent-placeholder":"","error-messages":_vm.lastNameValidationError},on:{"input":function($event){{
              _vm.updateState('lastName', $event);
              _vm.updateState('contractId', null);
              _vm.updateState('customerId', null);
              _vm.lastNameValidationError = _vm.validateLastName($event);
            }},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('confirmed')}}})],1)])]),_c('DisplayContainer',{staticStyle:{"background-color":"transparent"},attrs:{"isSubcomponent":"","label":"Adresse"}},[_c('ContractAddressSelection',{staticClass:"margin-top-1",attrs:{"state":_vm.currentState.contactAddress,"disabled":_vm.disabled},on:{"stateChanged":function($event){return _vm.updateState('contactAddress', $event)},"confirmed":function($event){return _vm.$emit('confirmed')}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }