<template>
  <div>
    <OrderProcessDialogue :previousDataSelection="null" />
  </div>
</template>

<script>
import OrderProcessDialogue from '@/components/orderEntry/orderCreation/OrderProcessDialogue.vue';

export default {
  name: 'OrderProcessStart',
  components: {
    OrderProcessDialogue
  },
  data: () => ({})
};
</script>

<style lang="scss" scoped></style>
