<template>
  <div>
    <v-card elevation="1">
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <h2>Prozess: {{ title }}</h2>
            <p>{{ description }}</p>
          </v-col>
          <v-col cols="9">
            <div class="bpmn-container" @click="showDialog = true">
              <vue-bpmn :url="url" @error="handleError" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showDialog" width="80vw">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Prozess: {{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="showDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <div class="diagram-large">
            <vue-bpmn :url="url" @error="handleError" />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueBpmn from 'vue-bpmn';
import NotificationObject from '@/main/NotificationObject.js';

export default {
  name: 'InfoCard',
  components: {
    VueBpmn,
    NotificationObject
  },
  props: {
    url: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    description: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: () => ({
    publicPath: process.env.BASE_URL,
    bpmnShow: true,
    showDialog: false
  }),
  methods: {
    handleError: function () {
      this.$store.commit(
        'addNotification',
        new NotificationObject(
          'error',
          'Der Prozess konnte nicht erfolgreich geladen werden!'
        )
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.viewer {
  margin: var(--goe-spacing-2);
  user-select: none;
  pointer-events: none;
  transition: all 0.1s ease-in-out;
  display: grid;
}

.vue-bpmn-diagram-container {
  height: 100%;
  width: 100%;
}
.bpmn-container {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.diagram-large {
  padding-top: 20px;
  height: 80vh;
}
</style>
