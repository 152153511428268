<template>
  <div>
    <div v-if="!isLoading" class="input-wrapper">
      <CustomerDetails
        v-if="customerData"
        :data="customerData"
        :parseSalutation="true"
        title="Kundendaten"
      />
      <DisplayContainer label="Vertragsdaten">
        <div class="input-row">
          <DataDisplay label="Vertragsnummer">
            {{ currentState.selectedContract.contractId }}
          </DataDisplay>
          <DataDisplay label="Vertragstyp">
            {{
              getContractTypeString(currentState.selectedContract.contractType)
            }}
          </DataDisplay>
        </div>
        <div class="input-row">
          <DataDisplay label="Erstellungsdatum">
            {{ formatDate(currentState.selectedContract.creationDate) }}
          </DataDisplay>
          <DataDisplay label="Datum Auftragsbestätigung">
            {{ formatDate(currentState.selectedContract.confirmationDate) }}
          </DataDisplay>
        </div>
      </DisplayContainer>
      <v-tabs
        v-model="selectedTab"
        bg-color="primary"
        @change="onTabChange()"
      >
        <v-tab href="#withdrawal">
          Widerruf
        </v-tab>
        <v-tab href="#cancellation">
          Kündigung
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item value="withdrawal">
          <DisplayContainer>
            <div class="input-row">
              <DatePicker
                label="Eingangsdatum Widerrufs *"
                :minDate="currentState.selectedContract.creationDate"
                :maxDate="today"
                :value="currentState.recallDate"
                :errorMessage="recallDateErrorMessage"
                @input="updateState('recallDate', $event)"
              />
              <v-select
                label="Grund des Widerrufs *"
                :items="reasonsForWithdrawal"
                :value="withdrawalReason ? withdrawalReason : undefined"
                :error-messages="withdrawalReasonErrorMessage"
                @input="updateState('withdrawalReason', $event)"
              />
              <v-switch
                v-model="currentState.sendCustomerCommunication"
                inset
                label="Widerrufsbestätigung versenden"
                @change="updateState('sendCustomerCommunication', $event)"
              ></v-switch>
            </div>
            <div v-if="withdrawalPeriodWarning" class="blocker">
              <div>
                <v-icon color="var(--goe-color-warning-dark)">
                  mdi-alert
                </v-icon>
                <div>
                  {{ withdrawalPeriodWarning }}
                </div>
              </div>
            </div>
          </DisplayContainer>
        </v-tab-item>
        <v-tab-item value="cancellation">
          <DisplayContainer>
            <div class="input-row">
              <DatePicker
                label="Kündigungsdatum"
                :value="currentState.cancellationDate"
                :errorMessage="cancellationDateErrorMessage"
                @input="updateState('cancellationDate', $event)"
              />
              <v-select
                label="Grund der Kündigung *"
                :items="reasonsForCancellation"
                :value="cancellationReason"
                :error-messages="cancellationReasonErrorMessage"
                @input="updateState('cancellationReason', $event)"
              />
              <v-switch
                v-model="currentState.sendCustomerCommunication"
                inset
                label="Kündigungsbestätigung versenden"
                @change="updateState('sendCustomerCommunication', $event)"
              ></v-switch>
            </div>
          </DisplayContainer>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div v-else class="loader-wrapper">
      <Loader class="loader" />
    </div>
  </div>
</template>

<script>
import Loader from '@/components/elements/Loader.vue';
import CustomerDetails from '@/components/CustomerDetails.vue';
import DatePicker from '@/components/elements/DatePicker.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';
import TimeUtility from '@/util/TimeUtility.js';

import { LocalDate } from '@js-joda/core';
import { HTTP } from '@/main/httpClient.js';

export default {
  name: 'WithdrawalDataInput',
  components: {
    Loader,
    CustomerDetails,
    DatePicker,
    DataDisplay,
    DisplayContainer
  },
  props: {
    state: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    selectTab: {
      type: String,
      required: true
    }
  },
  data: () => ({
    customerData: null,
    recallDateErrorMessage: null,
    withdrawalReasonErrorMessage: null,
    withdrawalPeriodWarning: null,
    cancellationDateErrorMessage: null,
    cancellationReasonErrorMessage: null,
    reasonsForWithdrawal: [],
    reasonsForCancellation: null,
    selectedTab: null
  }),
  computed: {
    currentState() {
      return { ...this.state };
    },
    today() {
      return LocalDate.now().toString();
    },
    maxRecallDate() {
      const maxDate = LocalDate.parse(
        this.currentState.selectedContract.confirmationDate
      ).plusDays(14);
      return maxDate.toString();
    },
    withdrawalReason() {
      return this.currentState.withdrawalReason;
      // return this.currentState.withdrawalReason ||
      //  this.reasonsForWithdrawal.length === 1
      //  ? this.reasonsForWithdrawal[0]
      //  : null;
    },
    cancellationReason() {
      return this.currentState.cancellationReason;
      // return this.currentState.cancellationReason ||
      //   this.reasonsForCancellation.length === 1
      //  ? this.reasonsForCancellation[0]
      //  : null;
    }
  },
  mounted: function () {
    this.selectedTab = this.selectTab;
    this.retrieveCustomerInfo();
    this.retrievePossibleReasons();
    this.validateInputs();
  },
  methods: {
    onTabChange: function () {
      this.currentState.selectedTab = this.selectedTab;
      this.$emit('tabChanged', this.currentState);
      this.validateInputs();
    },
    updateState: function (varName, varValue) {
      this.currentState[varName] = varValue;
      this.$emit('stateChanged', this.currentState);
      this.validateInputs();
    },
    retrieveCustomerInfo() {
      HTTP.get(
        `/customer/addressByType/${this.currentState.selectedContract.contractId}/INSTALLATION`
      ).then((resp) => {
        this.customerData = {
          ...resp.data,
          customerId: this.currentState.selectedContract.customerId
        };
      });
    },
    retrievePossibleReasons() {
      HTTP.get('/contracts/withdrawal/possibleReasons').then((resp) => {
        this.reasonsForWithdrawal = resp.data.filter((reason) =>
          reason.includes('Widerruf')
        );
        this.reasonsForCancellation = resp.data.filter(
          (reason) => !reason.includes('Widerruf')
        );
      });
    },
    validateInputs() {
      let validationErrors = [];
      if (this.selectedTab === 'withdrawal') {
        validationErrors = validationErrors.concat(this.checkWithdrawalDate());
        validationErrors = validationErrors.concat(
          this.checkWithdrawalReason()
        );
        this.checkWithdrawalPeriod();
      } else if (this.selectedTab === 'cancellation') {
        validationErrors = validationErrors.concat(
          this.checkCancellationDate()
        );
        validationErrors = validationErrors.concat(
          this.checkCancellationReason()
        );
      }
      this.$emit('inputValidationChanged', validationErrors);
    },
    checkWithdrawalDate() {
      if (this.currentState.recallDate) {
        this.recallDateErrorMessage = null;
      } else {
        this.recallDateErrorMessage =
          'Es muss ein Datum für den Eingang des Widerrufs angegeben werden!';
      }
      return this.recallDateErrorMessage ? [this.recallDateErrorMessage] : [];
    },
    checkWithdrawalReason() {
      if (this.currentState.withdrawalReason) {
        this.withdrawalReasonErrorMessage = null;
      } else {
        this.withdrawalReasonErrorMessage =
          'Es muss ein Grund für den Widerruf angegeben werden!';
      }
      return this.withdrawalReasonErrorMessage
        ? [this.withdrawalReasonErrorMessage]
        : [];
    },
    checkWithdrawalPeriod() {
      if (this.currentState.selectedContract.confirmationDate) {
        if (
          this.currentState.recallDate &&
          LocalDate.parse(this.currentState.recallDate).isAfter(
            LocalDate.parse(
              this.currentState.selectedContract.confirmationDate
            ).plusDays(14)
          )
        ) {
          this.withdrawalPeriodWarning =
            'Das gewählte Widerrufsdatum liegt außerhalb des gesetzlichen Widerrufszeitraums!';
        } else {
          this.withdrawalPeriodWarning = null;
        }
      } else {
        this.withdrawalPeriodWarning =
          'Das Versanddatum der Auftragsbestätigung ist unbekannt, der Widerrufszeitraum kann daher nicht überprüft werden!';
      }
    },
    checkCancellationDate() {
      if (this.currentState.cancellationDate) {
        this.cancellationDateErrorMessage = null;
      } else {
        this.cancellationDateErrorMessage =
          'Es muss ein Datum, ab wann die Kündigung wirksam werden soll, eingegeben werden!';
      }
      return this.cancellationDateErrorMessage
        ? [this.cancellationDateErrorMessage]
        : [];
    },
    checkCancellationReason() {
      if (this.currentState.cancellationReason) {
        this.cancellationReasonErrorMessage = null;
      } else {
        this.cancellationReasonErrorMessage =
          'Es muss ein Grund für die Kündigung angegeben werden!';
      }
      return this.cancellationReasonErrorMessage
        ? [this.cancellationReasonErrorMessage]
        : [];
    },
    getContractTypeString(contractType) {
      if (contractType === 'SERVICE_ORDER') {
        return 'Dienstauftrag';
      } else if (contractType === 'HOUSE_CONNECTION_ORDER') {
        return 'Hausanschlussauftrag';
      } else {
        return 'Vertragstyp unbekannt';
      }
    },
    formatDate(date) {
      return date ? TimeUtility.formatLocalDate(date) : '-';
    }
  }
};
</script>

<style scoped>
.loader-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loader {
  margin: auto;
  top: calc(50% - var(--goe-spacing-2));
  position: initial;
}

.blocker {
  display: block;
  width: 100%;
  height: 100%;
  left: var(--goe-spacing-0);
  right: auto;
  bottom: auto;
  opacity: 0.7;
}

.blocker > div {
  font-size: var(--goe-fontSize-normal);
  line-height: var(--goe-spacing-5);
  color: var(--goe-fontColor-dark);
  user-select: none;
  display: flex;
}
.blocker > div > div {
  margin-left: var(--goe-spacing-1);
}
</style>
