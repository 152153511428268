<template>
  <div class="components-grid">
    <div class="step-wrapper">
      <div class="description">
        Aktueller Schritt:
      </div>
      <div class="step">
        {{ currentStep }}
      </div>
    </div>
    <div class="process-step-wrapper">
      <SearchExtendedCustomerContracts
        v-if="currentStep == steps.SEARCH_CONTRACT_DATA"
        label="Widerrufsstart - Vertragssuche"
        :state="currentState"
        :isLoading="loading"
        @stateChanged="updateSearchState($event)"
        @inputValidationChanged="validationErrors = $event"
      />
      <WithdrawalDataInput
        v-if="currentStep === steps.WITHDRAWAL_DATA"
        :state="currentState"
        :isLoading="loading"
        :selectTab="currentState.selectedTab"
        @tabChanged="updateButtonLabel($event)"
        @stateChanged="updateWithdrawalState($event)"
        @inputValidationChanged="validationErrors = $event"
      />
    </div>
    <div class="step-navigation-wrapper">
      <v-btn
        v-if="displayBackButton"
        class="backButton"
        color="primary"
        large
        @click="updateFsm(availableSignals.BACKWARD)"
      >
        Zurück
      </v-btn>
      <v-tooltip
        v-if="displayForwardButton"
        top
        :disabled="validationErrors.length === 0"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            class="forwardButton"
            v-bind="attrs"
            v-on="on"
          >
            <v-btn
              :disabled="!currentInputsValid"
              color="primary"
              large
              @click="updateFsm(availableSignals.FORWARD)"
            >
              Nächster Schritt
            </v-btn>
          </div>
        </template>
        <ul>
          <li v-for="(error, index) in validationErrors" :key="index">
            {{ error }}
          </li>
        </ul>
      </v-tooltip>
      <v-tooltip
        v-if="displayCompleteButton"
        top
        :disabled="validationErrors.length === 0"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            class="forwardButton"
            v-bind="attrs"
            v-on="on"
          >
            <v-btn
              v-if="displayCompleteButton"
              class="forwardButton"
              :disabled="!currentInputsValid || processStarting"
              :loading="processStarting"
              color="primary"
              large
              @click="startProcess()"
            >
              {{ buttonLabel }}
            </v-btn>
          </div>
        </template>
        <ul>
          <li v-for="(error, index) in validationErrors" :key="index">
            {{ error }}
          </li>
        </ul>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';

import SearchExtendedCustomerContracts from '../SearchExtendedCustomerContracts.vue';
import WithdrawalDataInput from './WithdrawalDataInput.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

import NotificationObject from '@/main/NotificationObject.js';

export default {
  name: 'WithdrawalStart',
  components: {
    SearchExtendedCustomerContracts,
    WithdrawalDataInput
  },
  data: () => ({
    currentState: null,
    currentStep: null,
    contractDataLoading: false,
    processStarting: false,
    validationErrors: [],
    buttonLabel: null
  }),
  computed: {
    loading() {
      return this.contractDataLoading;
    },
    displayBackButton() {
      return this.currentStep !== this.steps.SEARCH_CONTRACT_DATA;
    },
    displayForwardButton() {
      return this.currentStep !== this.steps.WITHDRAWAL_DATA;
    },
    displayCompleteButton() {
      return this.currentStep === this.steps.WITHDRAWAL_DATA;
    },
    currentInputsValid() {
      return this.validationErrors.length === 0;
    }
  },
  mounted: function () {
    this.initState();
    this.updateButtonLabel(this.currentState);
  },
  created() {
    this.steps = {
      SEARCH_CONTRACT_DATA: 'Vertragssuche',
      WITHDRAWAL_DATA: 'Widerrufsdaten bearbeiten'
    };
    this.availableSignals = {
      BACKWARD: {
        id: 'BACKWARD'
      },
      FORWARD: {
        id: 'FORWARD'
      }
    };
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    initState: function () {
      this.currentState = {
        selectedContract: undefined,
        customerId: undefined,
        customerContracts: undefined,
        searchState: {
          customerId: undefined,
          contractId: undefined,
          firstName: undefined,
          lastName: undefined,
          contactAddress: {
            zipCode: undefined,
            city: undefined,
            street: undefined,
            houseNumber: undefined,
            houseNumberSupplement: undefined
          }
        },
        recallDate: null,
        withdrawalReason: null,
        cancellationDate: null,
        cancellationReason: null,
        selectedTab: 'withdrawal',
        sendCustomerCommunication: true
      };
      this.currentStep = this.steps.SEARCH_CONTRACT_DATA;
    },
    updateSearchState: function (state) {
      this.currentState = state;
    },
    updateWithdrawalState: function (state) {
      this.currentState = state;
    },
    updateButtonLabel: function (state) {
      this.currentState = state;
      this.buttonLabel =
        state.selectedTab === 'withdrawal'
          ? 'Vertragswiderruf starten'
          : 'Vertragskündigung starten';
      this.$forceUpdate();
    },
    updateFsm: function (signal) {
      switch (this.currentStep) {
        case this.steps.SEARCH_CONTRACT_DATA:
          // only forward possible
          this.currentStep = this.steps.WITHDRAWAL_DATA;
          break;
        case this.steps.WITHDRAWAL_DATA:
          // only backwards possible
          this.currentStep = this.steps.SEARCH_CONTRACT_DATA;
          break;
      }
      window.scrollTo(0, 0);
    },
    startProcess() {
      this.processStarting = true;
      HTTP.post('/contracts/withdrawal', {
        contractId: this.currentState.selectedContract.contractId,
        withdrawalOrCancellationDate:
          this.currentState.selectedTab === 'withdrawal'
            ? this.currentState.recallDate
            : this.currentState.cancellationDate,
        withdrawalOrCancellationReason:
          this.currentState.selectedTab === 'withdrawal'
            ? this.currentState.withdrawalReason
            : this.currentState.cancellationReason,
        contractTerminationMethod:
          this.currentState.selectedTab === 'withdrawal'
            ? 'WITHDRAWAL'
            : 'CANCELLATION',
        sendCustomerCommunication: this.currentState.sendCustomerCommunication
      })
        .then((resp) => {
          this.showMessage(
            'success',
            'Der Prozess wurde erfolgreich gestartet.'
          );
          this.$router.push('/');
        })
        .catch((err) => {
          console.log('error', err);
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Der Prozess konnte nicht gestartet werden.'
            )
          );
        })
        .finally(() => {
          this.processStarting = false;
        });
    }
  }
};
</script>
<style scoped>
.components-grid {
  display: grid;
  grid-template-rows: max-content max-content auto max-content;
  height: calc(100% - 2px);
}

.title-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  color: var(--goe-fontColor-dark);
  font-weight: var(--goe-fontWeight-light);
  padding: var(--goe-spacing-2);
  padding-bottom: var(--goe-spacing-1_5);
  background: var(--goe-background-highlight);
}

.title-wrapper h2 {
  margin-top: var(--goe-spacing-0_5);

  font-size: var(--goe-fontSize-normal);
  color: var(--goe-fontColor-dark);
  text-transform: uppercase;
  font-weight: var(--goe-fontWeight-light);
}

.step-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: var(--goe-spacing-2);
  margin: var(--goe-spacing-2);
  margin-left: auto;
  width: max-content;
}

.process-step-wrapper {
  display: grid;
  grid-template-columns: auto;

  padding: var(--goe-spacing-2);
}

.step-navigation-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  margin: var(--goe-spacing-2);
  padding: var(--goe-spacing-0) var(--goe-spacing-2);
  grid-template-areas: 'back forward ';
}

.step-navigation-wrapper > .backwardButton {
  grid-area: backward;
}

.step-navigation-wrapper > .forwardButton {
  grid-area: forward;
}

.step-navigation-wrapper NormalButton:nth-of-type(1) {
  order: 2;
}

.step-navigation-wrapper NormalButton:nth-of-type(2) {
  order: 1;
}

.step {
  color: var(--goe-fontColor-highlight);
  font-weight: var(--goe-fontWeight-bold);
  font-size: var(--goe-fontSize-normal);
}
</style>
