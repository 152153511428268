var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DisplayContainer',{attrs:{"label":_vm.label}},[_c('div',{staticClass:"search-wrapper"},[_c('SearchContractSelection',{attrs:{"label":"","state":_vm.currentState.searchState},on:{"stateChanged":function($event){return _vm.updateSearchState($event)},"confirmed":function($event){return _vm.retrieveCustomerContracts()}}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.retrieveCustomerContracts()}}},'v-btn',attrs,false),on),[_vm._v(" Vertrag suchen "),_c('v-icon',[_vm._v(" mdi-magnify ")])],1)]}}])},[_vm._v(" Verträge suchen ")])],1),(_vm.currentState.customerContracts || _vm.contractDataLoading)?_c('v-data-table',{staticClass:"elevation-1 search-list",attrs:{"headers":_vm.listHeaders,"items":_vm.contracts,"items-per-page":10,"item-key":"contractId","selectable-key":"contractId","show-select":"","single-select":"","no-data-text":"Verträge wurden noch nicht geladen.","loading":_vm.contractDataLoading,"loading-text":"Laden ... Bitte warten","server-items-length":_vm.tableRowCount,"options":_vm.options,"footer-props":{
        'items-per-page-options': [10, 20, 30, 40, 50, -1]
      }},on:{"update:options":function($event){_vm.options=$event},"item-selected":function($event){return _vm.selectedItemChanged($event)},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(){return [_c('v-switch',{staticClass:"pa-3",attrs:{"label":"Deaktivierte Verträge anzeigen"},model:{value:(_vm.showDeactivatedContracts),callback:function ($$v) {_vm.showDeactivatedContracts=$$v},expression:"showDeactivatedContracts"}})]},proxy:true},{key:"item.compactAddress",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"row"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('div',_vm._g({},on),[(item.addressIcon)?_c('v-icon',{staticClass:"icon-light"},[_vm._v(" "+_vm._s(item.addressIcon)+" ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.addressType))])]),_c('div',{staticClass:"label-address"},[_vm._v(" "+_vm._s(item.compactAddress)+" ")])],1)]}},{key:"item.creationDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.creationDate))+" ")]}},{key:"item.dateDeactivated",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.dateDeactivated))+" ")]}},{key:"item.dateActivated",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.dateActivated))+" ")]}}],null,true),model:{value:(_vm.selectedItemValue),callback:function ($$v) {_vm.selectedItemValue=$$v},expression:"selectedItemValue"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }