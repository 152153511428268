<template>
  <div>
    <div v-if="!isLoading" class="input-wrapper">
      <CustomerDetails
        v-if="currentState !== null && currentState.contactInformation !== null"
        :data="currentState.contactInformation"
        :parseSalutation="true"
        title="Kundendaten"
      />
      <div></div>
      <div class="group">
        <div>Portierungsdaten</div>
        <div>
          <div class="input-row">
            <div>
              <div class="label">
                Vertragsnummer
              </div>
              <div>
                {{ currentState.contractId }}
              </div>
            </div>
          </div>
          <div class="input-row">
            <v-radio-group
              :value="currentState.portingData.withCancellation"
              label="WBCI-Prozess-Typ"
              :disabled="dataDisplay"
              @change="updatePortingDataState('withCancellation', $event)"
            >
              <v-radio
                label="Rufnummerportierung mit Kündigung (KUE-MRN)"
                :value="true"
              />
              <v-radio
                label="Reine Rufnummernportierung (RRNP)"
                :value="false"
              />
            </v-radio-group>
          </div>
          <div class="input-row">
            <v-radio-group
              :value="currentState.portingData.portAllNumbers"
              label="Alle Rufnummern portieren?"
              :disabled="dataDisplay"
              @change="updatePortingDataState('portAllNumbers', $event)"
            >
              <v-radio label="Alle Rufnummern" :value="true" />
              <v-radio label="Nur ausgewählte Rufnummern" :value="false" />
            </v-radio-group>
          </div>
          <div class="input-row">
            <DatePicker
              label="Portierungsdatum *"
              :disabled="dataDisplay"
              :minDate="minPortingDate"
              :value="state.portingData.portingDate"
              :errorMessage="portingDateErrorMessage"
              disableWeekend
              disableHolidays
              @input="updatePortingDataState('portingDate', $event)"
            />
          </div>
        </div>
      </div>
      <div class="group">
        <div>Rufnummernauswahl</div>
        <div>
          <div v-if="!dataDisplay" class="table-input-wrapper">
            <v-text-field
              v-model="newNumberInput"
              class="table-input"
              filled
              hide-details
              dense
              single-line
              background-color="white"
              label="Neue Rufnummer"
              placeholder="Neue Rufnummer..."
              :rules="[
                (val) =>
                  (!!val && !isNaN(val)) ||
                  'Die Telefonnummer muss numerisch sein!',
                (val) =>
                  (!!val && val.startsWith('0')) ||
                  'Die Telefonnummer muss mit 0 beginnen!',
                (val) =>
                  (!!val && val.length >= 6 && val.length <= 14) ||
                  'Die Telefonnummer muss zwischen 6 und 14 Zeichen lang sein!'
              ]"
              @keydown.enter="tableInputValid ? saveNumber() : null"
            />
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="btn-add-number"
                  color="primary"
                  v-bind="attrs"
                  depressed
                  :disabled="!tableInputValid || numberListLoading"
                  :loading="numberListLoading"
                  v-on="on"
                  @click="saveNumber()"
                >
                  <v-icon>mdi-plus-thick</v-icon>
                </v-btn>
              </template>
              <span>Rufnummer hinzufügen</span>
            </v-tooltip>
          </div>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Portierung?</th>
                  <th>
                    Rufnummer
                    <template v-if="!!numberActivationErrorMessage">
                      <span> — </span>
                      <span class="error-message">
                        {{ numberActivationErrorMessage }}
                      </span>
                    </template>
                  </th>
                  <th v-if="!dataDisplay"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(
                    number, index
                  ) in currentState.portingData.phoneNumbers.filter(
                    (number) => !dataDisplay || number.active
                  )"
                  :key="number.number"
                >
                  <td>
                    <v-switch
                      v-if="!dataDisplay"
                      :input-value="number.active"
                      inset
                      @change="updateNumber(index, $event)"
                    />
                    <v-icon v-else>
                      {{
                        number.active ? 'mdi-check-circle' : 'mdi-close-circle'
                      }}
                    </v-icon>
                  </td>
                  <td>
                    {{ number.number }}
                  </td>
                  <td v-if="!dataDisplay">
                    <DialogButtonWithTooltip
                      tooltipTop
                      iconName="mdi-delete"
                      tooltipText="Telefonnummer löschen"
                      confirmButtonColor="var(--goe-background-danger)"
                      confirmButtonText="Löschen"
                      customButtonClass="btn-remove-number"
                      @onAction="deleteNumber(number.number)"
                    >
                      <template v-slot:dialogTitle>
                        Telefonnummer löschen?
                      </template>
                      <template v-slot:dialogText>
                        Die Telefonnummer <b>{{ number.number }}</b> wird
                        unwiderruflich gelöscht. Möchten Sie fortfahren?
                      </template>
                    </DialogButtonWithTooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>
      <div class="group">
        <div>Neue Rufnummern</div>
        <div class="input-row margin-top-1">
          <v-select
            v-model="currentState.portingData.numberRequestPhoneNumbers"
            :items="numberProvisionablePhoneNumbers"
            :disabled="dataDisplay"
            dense
            label="Anzahl neue Rufnummern"
            @change="numberRequestPhoneNumbersChange()"
          >
            <template v-slot:item="data">
              <div class="label">
                {{ data.item }}
              </div>
            </template>
            <template v-slot:selection="data">
              <div class="label" style="font-size: 1.1em">
                {{ data.item }}
              </div>
            </template>
          </v-select>
        </div>
      </div>
      <CarrierSelection
        :value="currentState.portingData.selectedCarrier"
        :disabled="dataDisplay"
        label="Aktueller Anbieter"
        @input="updatePortingDataState('selectedCarrier', $event)"
      />
    </div>
    <div v-else class="loader-wrapper">
      <Loader class="loader" />
    </div>
  </div>
</template>

<script>
import Loader from '@/components/elements/Loader.vue';
import CustomerDetails from '@/components/CustomerDetails.vue';
import DatePicker from '@/components/elements/DatePicker.vue';
import CarrierSelection from '@/components/CarrierSelectionVuetify.vue';
import { LocalDate, DayOfWeek } from '@js-joda/core';
import DialogButtonWithTooltip from '@/components/elements/DialogButtonWithTooltip.vue';
import { HTTP } from '@/main/httpClient.js';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import NotificationObject from '@/main/NotificationObject.js';

export default {
  name: 'PortingDataCheck',
  components: {
    Loader,
    CustomerDetails,
    DatePicker,
    CarrierSelection,
    DialogButtonWithTooltip
  },
  props: {
    state: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    dataDisplay: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    portingDateErrorMessage: null,
    numberActivationErrorMessage: null,
    newNumberInput: null,
    numberListLoading: false,
    numberProvisionablePhoneNumbers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  }),
  computed: {
    currentState() {
      return { ...this.state };
    },
    minPortingDate() {
      let minDate = LocalDate.now();
      if (this.currentState.portingData.withCancellation) {
        minDate = minDate.plusDays(30);
      }
      const dayOfWeek = minDate.dayOfWeek();
      if (dayOfWeek === DayOfWeek.SATURDAY) {
        minDate = minDate.plusDays(2);
      } else if (dayOfWeek === DayOfWeek.SUNDAY) {
        minDate = minDate.plusDays(1);
      }
      return minDate.toString();
    },
    tableInputValid() {
      return (
        !!this.newNumberInput &&
        !isNaN(this.newNumberInput) &&
        this.newNumberInput.startsWith('0') &&
        this.newNumberInput.length >= 6 &&
        this.newNumberInput.length <= 14
      );
    }
  },
  mounted: function () {
    this.validateInputs();
    this.currentState.portingData.numberRequestPhoneNumbers = 0;
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    updateState: function (varName, varValue) {
      this.currentState[varName] = varValue;
      this.$emit('stateChanged', this.currentState);
      this.validateInputs();
    },
    updatePortingDataState: function (varName, varValue) {
      this.currentState.portingData[varName] = varValue;
      this.$emit('stateChanged', this.currentState);
      this.validateInputs();
    },
    updateNumber(index, active) {
      this.currentState.portingData.phoneNumbers[index].active = active;
      this.$emit('stateChanged', this.currentState);
      this.validateInputs();
    },
    addNewNumber(newNumberString) {
      var phoneNumber = {
        number: newNumberString,
        active: false
      };
      this.currentState.portingData.phoneNumbers.push(phoneNumber);
      this.$emit('stateChanged', this.currentState);
      this.validateInputs();
    },
    removeNumber(number) {
      const numberIndex = this.currentState.portingData.phoneNumbers.findIndex(
        (phoneNumber) => phoneNumber.number === number
      );
      if (numberIndex !== -1) {
        this.currentState.portingData.phoneNumbers.splice(numberIndex, 1);
        this.$emit('stateChanged', this.currentState);
        this.validateInputs();
      }
    },
    validateInputs() {
      let validationOk = true;
      if (this.currentState.portingData.portingDate === null) {
        validationOk = false;
        this.portingDateErrorMessage =
          'Es muss ein Portierungsdatum gewählt werden.';
      } else if (
        this.currentState.portingData.withCancellation &&
        new Date(this.currentState.portingData.portingDate) <
          new Date(this.minPortingDate)
      ) {
        validationOk = false;
        this.portingDateErrorMessage =
          'Das gewählte Portierungsdatum liegt vor dem frühest möglichen Portierungsdatum.';
      } else {
        this.portingDateErrorMessage = null;
      }
      let anyNumberActive = false;
      this.currentState.portingData.phoneNumbers.forEach((element) => {
        anyNumberActive = anyNumberActive || element.active;
      });
      if (!anyNumberActive) {
        this.numberActivationErrorMessage =
          'Es muss mindestens eine Rufnummer aktiviert werden!';
        validationOk = false;
      } else {
        this.numberActivationErrorMessage = null;
      }
      this.$emit('inputValidationChanged', validationOk);
    },
    invalid() {
      console.log('is invalid');
    },
    saveNumber: function () {
      this.numberListLoading = true;
      console.log(this.newNumberInput);
      HTTP.post('/subscriber/number', {
        contractId: this.currentState.contractId,
        phoneNumber: this.newNumberInput
      })
        .then((resp) => {
          this.addNewNumber(this.newNumberInput);
          this.showMessage(
            'success',
            'Die Telefonnummer wurde erfolgreich hinzugefügt.'
          );
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Telefonnummer konnte nicht hinzugefügt werden!'
            )
          )
        )
        .finally(() => {
          this.numberListLoading = false;
          this.newNumberInput = null;
        });
    },
    deleteNumber(number) {
      HTTP.delete('/subscriber/number', {
        data: {
          contractId: this.currentState.contractId,
          phoneNumber: number
        }
      })
        .then((resp) => {
          this.removeNumber(number);
          this.showMessage(
            'success',
            'Die Telefonnummer wurde erfolgreich gelöscht.'
          );
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Telefonnummer konnte nicht gelöscht werden!'
            )
          )
        )
        .finally(() => {});
    },
    numberRequestPhoneNumbersChange() {
      console.log('numberRequestPhoneNumbersChanged to:', this.currentState.portingData.numberRequestPhoneNumbers);
    }
  }
};
</script>

<style scoped>
div.input-row >>> .v-input--radio-group legend.v-label {
  font-size: var(--goe-fontSize-small);
  color: var(--goe-fontColor-light);
}

.loader-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loader {
  margin: auto;
  top: calc(50% - var(--goe-spacing-2));
  position: initial;
}

tr > td:nth-child(1),
tr > td:nth-child(3) {
  min-width: min-content;
  width: 5%;
}

td >>> .btn-remove-number:hover {
  color: red;
}

.btn-add-number {
  height: 41px !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

div.table-input-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-0);
  margin-bottom: var(--goe-spacing-0) !important;
}

.table-input {
  width: 100%;
  border-top-right-radius: 0px;
  font-size: var(--goe-fontSize-normal);
}

.table-input >>> .v-input__slot {
  padding: 0px 16px !important;
}

.error-message {
  color: var(--goe-color-error);
  font-weight: var(--goe-fontWeight-bold);
  margin-left: 5px;
}
</style>
