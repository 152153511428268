<template>
  <div class="components-grid">
    <CurrentStepDisplay :currentStep="context.currentStepLabel" />

    <div v-if="!context.backendCallActive" class="process-step-wrapper">
      <SearchExtendedCustomerContracts
        v-if="currentState.matches('selectContractId')"
        label="Provisionierungsstart - Vertragssuche"
        :state="context.searchContractState"
        :isLoading="context.backendCallActive"
        @stateChanged="updateFsm('UPDATE_SEARCH_CONTRACT', $event)"
        @inputValidationChanged="searchContractValidationCheck($event)"
      />
      <ProvisioningOptions
        v-if="currentState.matches('selectProvisioningOptions')"
        :key="'selectProvisioningOptions'"
        :contractData="context.contractData"
        :previouslySelectedProvisioningSettings="
          context.selectedProvisioningSettings
        "
        @selectedProvisioningSettingsChanged="
          updateFsm('UPDATE_SELECTED_PROVISIONING_SETTINGS', $event)
        "
      />
      <ProvisioningOptions
        v-if="currentState.matches('confirmSelection')"
        :key="'confirmSelection'"
        :disabled="true"
        :contractData="context.contractData"
        :previouslySelectedProvisioningSettings="
          context.selectedProvisioningSettings
        "
      />
    </div>
    <Loader v-else />

    <div class="step-navigation-wrapper">
      <v-btn
        v-if="context.showDisplayBackButton === true"
        :disabled="context.backendCallActive"
        class="backButton"
        color="primary"
        large
        @click="backward()"
      >
        Zurück
      </v-btn>
      <v-btn
        class="forwardButton"
        :disabled="!context.subComponentValid || context.backendCallActive"
        color="primary"
        large
        @click="forward()"
      >
        {{ context.nextStepButtonLabel }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import CurrentStepDisplay from '@/components/CurrentStepDisplay.vue';
import { createMachine, interpret, assign } from 'xstate';
import { HTTP } from '@/main/httpClient.js';
import Loader from '@/components/Loader.vue';
import ProvisioningOptions from '@/processLaunch/provisioning/tv/ProvisioningOptions.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import NotificationObject from '@/main/NotificationObject.js';
import SearchExtendedCustomerContracts from '@/processLaunch/SearchExtendedCustomerContracts.vue';

export default {
  name: 'ProcessDetails',
  components: {
    CurrentStepDisplay,
    Loader,
    ProvisioningOptions,
    ErrorMessageBuilder,
    SearchExtendedCustomerContracts
  },
  data: () => ({
    viewStateService: null,
    currentState: null,
    context: null
  }),
  created() {
    const newFsm = buildFsm(this);
    // Interpret the machine and store it in data
    this.viewStateService = interpret(newFsm);
    // Start with the machine's initial state
    this.currentState = newFsm.initialState;
    // Start with the machine's initial context
    this.context = newFsm.context;
    // Start service on component creation
    this.viewStateService
      .onTransition((state) => {
        // Update the current state component data property with the next state
        this.currentState = state;
        // Update the context component data property with the updated context
        this.context = state.context;
      })
      .start();
  },
  methods: {
    updateFsm(updateEvent, payload) {
      this.send({ type: updateEvent, payload: payload });
    },
    backward() {
      this.send('BACKWARD');
    },
    forward() {
      this.send('FORWARD');
    },
    // Send events to the service
    send(event) {
      this.viewStateService.send(event);
    },
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    searchContractValidationCheck(event) {
      if (event === null || event === undefined) {
        this.updateFsm('UPDATE_SUBCOMPONENT_VALID', false);
        return;
      }

      if (event.length === 0) {
        this.updateFsm('UPDATE_SUBCOMPONENT_VALID', true);
        const contractId =
          this.context.searchContractState.selectedContract.contractId;
        this.updateFsm('UPDATE_CONTRACT_ID', { contractId: contractId });
        return;
      }
      this.updateFsm('UPDATE_SUBCOMPONENT_VALID', false);
    }
  }
};

function fetchContractData(contractId) {
  return HTTP.get(`/provisioning/start/${contractId}`);
}

function buildProvisioningDto(selectedProvisioningSettings) {
  return {
    contractId: selectedProvisioningSettings.contractId,
    customerId: selectedProvisioningSettings.customerId,
    tvProduct: selectedProvisioningSettings.tvHauptProduct,
    setTopBox: selectedProvisioningSettings.stbProduct,
    numberOfBoxes: selectedProvisioningSettings.numberOfSticks,
    setupDate: selectedProvisioningSettings.setupDate
  };
}

function startProcess(provisioningDto) {
  return HTTP.post('/provisioning/tv/start', provisioningDto).catch((err) => {
    console.log(err.response);
    var message = ErrorMessageBuilder.buildErrorMessage(err.response.data);
    throw message;
  });
}

function buildFsm(executionContext) {
  return createMachine(
    {
      id: 'view',
      initial: 'selectContractId',
      context: {
        currentExecutionContext: executionContext, // the object one would call 'this' during execution
        currentStepLabel: '',
        nextStepButtonLabel: '',
        showDisplayBackButton: false,
        subComponentValid: false,
        backendCallActive: false,
        selectedContractId: null,
        contractData: null,
        selectedProvisioningSettings: null,
        searchContractState: {
          selectedContract: undefined,
          customerId: undefined,
          customerContracts: undefined,
          recallDate: null,
          searchState: {
            customerId: undefined,
            contractId: undefined,
            firstName: undefined,
            lastName: undefined,
            contactAddress: {
              zipCode: undefined,
              city: undefined,
              street: undefined,
              houseNumber: undefined,
              houseNumberSupplement: undefined
            }
          }
        }
      },
      states: {
        selectContractId: {
          entry: assign({
            currentStepLabel: 'Vertragssuche',
            nextStepButtonLabel: 'Nächster Schritt',
            showDisplayBackButton: false,
            subComponentValid: false,
            backendCallActive: false,
            selectedProvisioningSettings: null
          }),
          on: {
            UPDATE_SEARCH_CONTRACT: {
              internal: true,
              actions: [
                (context, event) => {
                  context.searchContractState = event.payload;
                }
              ]
            },
            UPDATE_CONTRACT_ID: {
              internal: true,
              actions: [
                (context, event) => {
                  context.selectedContractId = event.payload.contractId;
                }
              ]
            },
            UPDATE_SUBCOMPONENT_VALID: {
              internal: true,
              actions: assign({
                subComponentValid: (context, event) => event.payload
              })
            },
            FORWARD: [
              {
                target: 'fetchContractData',
                cond: 'inputIsValid'
              }
            ]
          }
        },
        fetchContractData: {
          entry: assign({ backendCallActive: true }),
          exit: assign({ backendCallActive: false }),
          invoke: {
            src: (context, event) =>
              fetchContractData(context.selectedContractId),
            onDone: {
              target: 'selectProvisioningOptions',
              actions: assign({
                contractData: (context, event) => event.data.data
              })
            },
            onError: {
              target: 'selectContractId',
              actions: [
                (context, event) => {
                  context.currentExecutionContext.showMessage(
                    'error',
                    'Die Vertragsdaten konnten nicht geladen werden.'
                  );
                }
              ]
            }
          }
        },
        selectProvisioningOptions: {
          entry: [
            assign({
              currentStepLabel: 'Auswahl Provisionierungsdaten',
              nextStepButtonLabel: 'Nächster Schritt',
              showDisplayBackButton: true,
              subComponentValid: false
            }),
            (context, event) => {
              window.scroll(0, 0);
            }
          ],
          on: {
            UPDATE_SELECTED_PROVISIONING_SETTINGS: {
              internal: true,
              actions: [
                (context, event) => {
                  context.selectedProvisioningSettings =
                    event.payload.selectedProvisioningSettings;
                  context.subComponentValid = event.payload.isValid;
                }
              ]
            },
            BACKWARD: 'selectContractId',
            FORWARD: [
              {
                target: 'confirmSelection',
                cond: 'inputIsValid'
              }
            ]
          }
        },
        confirmSelection: {
          entry: [
            assign({
              currentStepLabel: 'Bestätigen',
              nextStepButtonLabel: 'Provisionierung Starten',
              showDisplayBackButton: true,
              subComponentValid: true
            }),
            (context, event) => {
              window.scroll(0, 0);
            }
          ],
          on: {
            BACKWARD: 'selectProvisioningOptions',
            FORWARD: 'startProcess'
          }
        },
        startProcess: {
          entry: assign({ backendCallActive: true }),
          exit: assign({ backendCallActive: false }),
          invoke: {
            src: (context, event) =>
              startProcess(
                buildProvisioningDto(context.selectedProvisioningSettings)
              ),
            onDone: {
              target: 'close',
              actions: function (context) {
                context.currentExecutionContext.showMessage(
                  'success',
                  'Der Prozess wurde erfolgreich gestartet.'
                );
              }
            },
            onError: {
              target: 'confirmSelection',
              actions: function (context, event) {
                context.currentExecutionContext.showMessage(
                  'error',
                  event.data
                );
              }
            }
          }
        },
        close: {
          type: 'final',
          exit: function (context) {
            context.currentExecutionContext.$router.push('/');
          },
          on: {}
        }
      }
    },
    {
      guards: {
        inputIsValid: (context) => context.subComponentValid === true
      }
    }
  );
}
</script>
<style scoped lang="scss">
.components-grid {
  display: grid;
  grid-template-rows: max-content max-content auto max-content;
  height: calc(100% - 2px);
}

.process-step-wrapper {
  display: grid;
  grid-template-columns: auto;
  padding: var(--goe-spacing-2);
}

.step-navigation-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  margin: var(--goe-spacing-2);
  padding: var(--goe-spacing-0) var(--goe-spacing-2);
  grid-template-areas: 'back forward ';
}

.step-navigation-wrapper > .forwardButton {
  grid-area: forward;
}

.step-navigation-wrapper NormalButton:nth-of-type(1) {
  order: 2;
}

.step-navigation-wrapper NormalButton:nth-of-type(2) {
  order: 1;
}
</style>
