<template>
  <div>
    <ProcessDiagramCard
      :title="title"
      :description="description"
      :url="`${publicPath}${lastBpmnSrc}`"
      class="process-info-card"
    />
    <v-card elevation="1">
      <slot></slot>
    </v-card>
  </div>
</template>

<script>
import NotificationObject from '@/main/NotificationObject.js';
import VueBpmn from 'vue-bpmn';
import ProcessDiagramCard from './ProcessDiagramCard.vue';

export default {
  name: 'ProcessLaunchWrapper',
  components: {
    NotificationObject,
    VueBpmn,
    ProcessDiagramCard
  },
  props: {
    lastBpmnSrc: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    description: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: () => ({
    publicPath: process.env.BASE_URL,
    bpmnShow: true
  }),
  methods: {
    handleError: function () {
      this.$store.commit(
        'addNotification',
        new NotificationObject(
          'error',
          'Der Prozess konnte nicht erfolgreich geladen werden!'
        )
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.vue-bpmn-diagram-container {
  height: 100%;
  width: 100%;
}
div::v-deep .v-card {
  margin-bottom: 20px;
}
</style>
